import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSlidesData, updateScriptWithTrans } from '../../../../Actions/VideoAction';
import { onGoogleLangFetch, } from '../../../../Actions/ClientsAction';
import { useEffect } from 'react';


const Story = ({ slideData, memberShip }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const dispatch = useDispatch()

    const [lang, setLang] = useState([])
    const [state, setState] = useState({
        loader: false,
        language: ''
    })


    const updateStoryTile = (index) => {
        if (selectedSlideIndex !== index) {
            dispatch(updateSlidesData(selectedSlideIndex, index))
        }
    }

    const handleVal = () => {
        setState({
            ...state,
            loader: true
        })
        dispatch(updateScriptWithTrans(state, setState, slideData))
    }


    useEffect(() => {
        if (memberShip.length > 0) {
            dispatch(onGoogleLangFetch(setLang, memberShip))
        }
    }, [memberShip])

    return (
        <div className="tabInner">
            <div className="story-block">

                {memberShip.includes("platinum") || memberShip.includes("platinum-lite") ||  memberShip.includes("fastpass") ||  memberShip.includes("bundle") ?
                    <div className='add-block-single'>
                        <div className="translate-drop mb-3 mt-2">
                            <select
                                name="tCode"
                                onChange={(e) => setState({
                                    ...state,
                                    language: e.target.value
                                })}
                            >
                                <option value="">Select Language</option>
                                {lang.length > 0 ?
                                    lang.map((curElem) => {
                                        return (
                                            <option
                                                value={curElem.code}
                                                key={curElem.id}
                                            >
                                                {curElem.name}
                                            </option>
                                        )
                                    })
                                    : ""}
                            </select>

                            <button className="demoLink trans_btn"
                                onClick={handleVal}
                                disabled={state.language === "" || state.loader ? true : false}
                                style={{ background: state.language === "" ? "gray" : "" }}
                            >

                                {state.loader ?
                                    <>Translating <i className="fa fa-spinner fa-spin mx-1" /></>
                                    : "Translate"}
                            </button>
                        </div>
                        <hr />
                    </div>
                    : ""}

                <ul>
                    {slideData.length > 0 ?
                        slideData.map((curElem, index) => {
                            return (
                                <li
                                    className={`${selectedSlideIndex === index ? 'active cursor-pointer' : 'cursor-pointer'}`}
                                    key={index}
                                    onClick={() => updateStoryTile(index)}
                                >
                                    <div className="story-block-single" >
                                        <p className={selectedSlideIndex === index ? "text-light" : `text-dark`}>{curElem.originalText}</p>
                                    </div>
                                </li>
                            )
                        })
                        : ""}
                </ul>
            </div>
        </div>
    );
};

export default Story;
