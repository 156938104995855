import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { onCreateClient, onFetch } from '../../Actions/ClientsAction';
import modalCloseIcon from '../../images/modal-close.png';

const ClientModal = ({ show, handleClose, aId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [industry, setIndustry] = useState([])
    const [state, setState] = useState({
        agencyId: aId,
        name: "",
        email: "",
        password: "",
        industry: "",
        isEmail:false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if(name==="isEmail"){
            if(state.isEmail){
                setState({
                    ...state,
                    isEmail:false
                })
            }else{
                setState({
                    ...state,
                    isEmail:true
                })
            }
        }else{
            setState({
                ...state,
                [name]: value
            })
        }
    }
    const getIndustry = () => {
        let ep = "list-industry"
        let data = {}
        dispatch(onFetch(ep, data, setIndustry))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onCreateClient(state, setLoader, navigate))
    }
    useEffect(() => {
        getIndustry()
    }, [])

    return (
        <Modal className="VideoModal small" show={show} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <div className="formSec">

                        <div className="inpLabelWrap mt-0">
                            <div className="inpLabel">
                                <label htmlFor="name">Name</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Client Name Here"
                                    type="text"
                                    name='name'
                                    value={state.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap">
                            <div className="inpLabel">
                                <label htmlFor="email">Email</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Client Email Here"
                                    type="email"
                                    name='email'
                                    value={state.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap">
                            <div className="inpLabel">
                                <label htmlFor="password">Password</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Client Password Here"
                                    type="password"
                                    name='password'
                                    value={state.password}
                                    onChange={handleChange}
                                    onKeyDown={(event) => {
                                        if (event.code === "Space") {
                                          event.preventDefault();
                                        }
                                      }}
                                      minLength={6}
                                     maxLength={32}
                                    required
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap">
                            <div className="inpLabel">
                                <label>Industry</label>
                                <select
                                    className="inpLabel-inp"
                                    name='industry'
                                    value={state.industry}
                                    onChange={handleChange}
                                    required
                                >
                                    <option style={{ fontWeight: "bold", fontSize: 15, color: "#444242" }} value="">Select Industry</option>
                                    {industry.length > 0 ?
                                        industry.map((curElem, index) => {
                                            return (
                                                <option className="text-capitalize" key={index} value={curElem.id}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-12 p-0">
                          <div className="modBox mt-4 text-start">
                              <div className="inpLabel mt-0 d-flex align-items-center justify-content-between">
                                  <p style={{ color: "#ff6363", fontSize: "12px", fontWeight: "600" }} htmlFor="">Email Credentials to this client.</p>

                                  <div className="switch_wrapper">
                                       <label className="switch">
                                          <input
                                               type="checkbox"
                                              name='isEmail'
                                              checked={state.isEmail}
                                               onChange={handleChange}
                                               style={{ width: '100%' }}
                                          />
                                           <span className="slider round"></span>
                                      </label>
                                  </div>
                              </div>
                           </div>
                      </div>

                        <button type='submit' className="btn-block inpBtn mt-3 create-modal-btn">
                            {loader ? <>Creating Client <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "20px" }} /></> : "Create Client"}
                        </button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default ClientModal