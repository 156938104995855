import React from 'react';
import { Tab, Nav } from "react-bootstrap";
import UploadComponent from '../../uploadFiles/UploadComponent';
import Music from './Music';

const Audio = ({ activeMusic }) => {
  return (
    <div className="tabInner">
      <div className="media-block">
        <Tab.Container id="left-tabs-example-2" defaultActiveKey="music-tab">
          <Nav variant="pills" className="inTabNav full">
            <Nav.Item>
              <Nav.Link eventKey="music-tab">Music</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="upload-audio">Upload Music</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>

            <Tab.Pane eventKey="music-tab">
              <Music
                activeMusic={activeMusic}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="upload-audio">
              <UploadComponent
                type="music"
                tabType="music"
              />
            </Tab.Pane>

          </Tab.Content>

        </Tab.Container>
      </div>
    </div>
  );
};

export default Audio;
