

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import closeModalx from "../../../../images/modal-close.png"
import { Tab, Nav, Modal } from "react-bootstrap";
import sample from "../../../../images/sample.png";
import HoverVideoPlayer from "react-hover-video-player";
import { FiTrash2 } from 'react-icons/fi';
import Outro from './Outro';
import { setIntroStatus, removeIntro, updateIntroOutro, onDeleteMediaIntroOutro, onFetchUploadFile, onFileUploadIntroOutro } from '../../../../Actions/VideoAction';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import SweetAlertTwo from '../../../SweetAlertTwo';


const Credit = () => {

  const dispatch = useDispatch()
  const video = useSelector(state => state.video)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [introData, setIntroData] = useState(false)
  const [introArr, setIntroArr] = useState([])

  const [loader, setLoader] = useState({
    introLoader: false,
    outroLoader: false
  });

  const [intOut, setIntOut] = useState({
    id: false,
    enable: false,
    type: '',
  })

  const handleStatusIntroOutro = (e) => {
    dispatch(setIntroStatus(e.target.checked))
    if (e.target.checked === false) {
      dispatch(removeIntro())
    }
  }


  const handleIntroOutro = (dataVal) => {
    fetchIntro()
    let data = {
      url: dataVal.path,
      nameData: dataVal.name,
      thumbData: dataVal.thumbnail
    }
    dispatch(updateIntroOutro('UPDATE_INTRO', data))
  }

  const handleLoader = (status, type) => {
    if (type === 'intro') {
      setLoader({
        ...loader,
        introLoader: status
      });
    }
    else if (type === 'outro') {
      setLoader({
        ...loader,
        outroLoader: status
      });
    }
  }

  const onInputVideo = (e, type) => {
    if (e.target.files[0].type === "video/mp4") {
      const allowedSize = 20000000;
      if (e.target.files[0].size < allowedSize) {
        handleLoader(true, type)
        const formData = new FormData();

        formData.append('file', e.target.files[0])
        formData.append('upload_type', type)
        formData.append('agencyId', video.agencyId)
        formData.append('clientId', video.clientId)

        dispatch(onFileUploadIntroOutro(formData, type, handleIntroOutro, handleLoader))
      } else {
        Swal.fire({
          icon: "error",
          title: 'Oops...',
          text: "Max allowed size for video is 20MB!",
          confirmButtonColor: "#d03e3e"
        })
      }
    } else {
      Swal.fire({
        icon: "error",
        title: 'Oops...',
        text: "You have Selected Invalid File Type!",
        confirmButtonColor: "#d03e3e"
      })
    }
  }

  const openDeletePopUp = (e, id) => {
    e.stopPropagation()
    setIntOut({
      ...intOut,
      id: id,
      enable: true,
      type: "intro"
    })
  }

  const onConfirm = (Swal) => {
    let formData = new FormData();
    formData.append('id', intOut.id);
    dispatch(onDeleteMediaIntroOutro(formData, fetchIntro, Swal))
    setIntOut({
      ...intOut,
      id: false,
      enable: false,
      type: "intro"
    })
  }

  const handleCancle = () => {
    setIntOut({
      ...intOut,
      id: false,
      enable: false,
      type: "intro"
    })
  }

  const fetchIntro = () => {
    const data = {
      type: "intro",
      agencyId: video.agencyId,
      clientId: video.clientId
    }
    dispatch(onFetchUploadFile(data, setIntroArr))

  }


  const selectIntro = (curElem) => {
    let data = {
      url: '',
      nameData: '',
      thumbData: ''
    }
    data.url = curElem.url
    data.nameData = curElem.name
    data.thumbData = curElem.poster
    handleClose()
    dispatch(updateIntroOutro('UPDATE_INTRO', data))
  }


  useEffect(() => {
    if (video.intro) {
      setIntroData(video.intro)
    }
  }, [video.intro])

  useEffect(() => {
    fetchIntro()
  }, []);

  return (
    <>
      <div className="tabInner">
        <div className="media-block">
          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title  mb-4">
                <h2>Intro</h2>
                <div className="switch-single">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={introData.enable}
                      onChange={(e) => handleStatusIntroOutro(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {introData.enable ?
                <>
                  <div className="fileUpload" >
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                    {
                      introData.name ?
                        introData.name.length > 23 ? `${introData.name.slice(0, 23)}...` : introData.name : "Upload Your Intro"}
                    <input
                      type="file"
                      className='cursor-pointer'
                      onChange={(e) => onInputVideo(e, 'intro')}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {loader.introLoader ? <i className="fa fa-spinner fa-spin loader-center" />
                            : <img
                              src={introData.thumbnail ? introData.thumbnail : sample}
                              alt=""
                              className="uploadImg"
                            />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className='itemDisp-right'>
                      <button onClick={handleShow}>Select Intro Video +</button>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                      <button className='model-close-button text-right' onClick={handleClose}>
                        <img src={closeModalx} alt="" style={{ filter: "brightness(0.5)" }} />
                      </button>
                      <Modal.Body style={{ color: '#ffffff' }}>
                        <div className="row">
                          <div className="col-sm-12 m-0">
                            <div className="tabInner">
                              <Tab.Container defaultActiveKey="intro-video">
                                <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                  <Nav.Item>
                                    <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded intro videos</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                  <Tab.Pane eventKey="intro-video">
                                    <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                      <div className="row">
                                        {introArr.length > 0 ?
                                          introArr.map((curElem) => {
                                            return (
                                              <div
                                                className="col-md-4 col-6"
                                                key={curElem.id}
                                                onClick={() => selectIntro(curElem, "intro")}
                                              >
                                                <div className="credit-video-card" style={{
                                                  border: "none",
                                                }}>
                                                  <div>
                                                    <>
                                                      <HoverVideoPlayer
                                                        className="my-1 cursor-pointer "
                                                        videoSrc={curElem.url}
                                                        pausedOverlay={
                                                          <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                        }
                                                        loadingOverlay={
                                                          <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                        }
                                                      />
                                                      <FiTrash2
                                                        className='remove-media'
                                                        onClick={(e) => openDeletePopUp(e, curElem.id)}
                                                        color="#ff6363"
                                                      />
                                                    </>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <div className='text-center' style={{ fontSize: 20 }}>
                                            You don't have uploaded Intro videos
                                          </div>}
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <SweetAlertTwo
                        show={intOut.enable}
                        title="Are You Sure?"
                        text="You won't be able to revert this!"
                        icon="warning"
                        showCancelButton={true}
                        confirmButtonColor='#3085d6'
                        cancelButtonColor='#d33'
                        confirmButtonText='Yes, delete it!'
                        confirmTitle='Deleting...'
                        confirmText="This won't be revert!"
                        confirmIcon='success'
                        handlePerform={onConfirm}
                        onCancel={handleCancle}
                      />
                    </Modal>

                  </div>
                </> : ''}
            </div>
          </div>

          <Outro />
        </div >
      </div >

    </>
  );
}

export default Credit;
