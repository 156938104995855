import React, { useState, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom";

import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { onChangeClient, onFetch, onGetClient, onUpdateClient, onUpdatePass } from "../../Actions/ClientsAction";
import { onGetAgencyData, onUploadImage } from "../../Actions/AgencyAction";
import Swal from "sweetalert2";
import CreateResources from "./Resources/CreateResources";
import { RiErrorWarningLine } from "react-icons/ri"
import Integration from '../Integration/Integration'

const CreateClients = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)
    const agency = useSelector(state => state.agency.data)
    const auth = useSelector((state) => state.auth);
    const [msg, setMsg] = useState("")
    const [industry, setIndustry] = useState([])

    const [loader, setLoader] = useState({
        pageLoader: true,
        submitLoader: false,
        imageLoader: false,
        passwordLoader: false
    })

    const [pass, setPass] = useState({
        id: cId,
        password: "",
        confirmPassword: ""
    })

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submitLoader: true
        })
        dispatch(onUpdateClient(clientData, loader, setLoader))
    }


    const handlePassword = (e) => {
        e.preventDefault()
        if (pass.password === pass.confirmPassword) {
            setLoader({
                ...loader,
                passwordLoader: true
            })
            dispatch(onUpdatePass(pass, loader, setLoader))
        } else {
            setMsg(<><RiErrorWarningLine /> Your password and confirm password do not match.</>)
        }
    }

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        // if (name === "isEmail") {
        //     value = checked
        // }
        if (name === "mode") {
            value = checked ? "0" : "1"
        }
        dispatch(onChangeClient(name, value))
    }

    const handleChangePass = (e) => {
        const { name, value } = e.target
        if (msg !== "") {
            setMsg("")
        }
        setPass({
            ...pass,
            [name]: value
        })
    }

    const handleImage = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    imageLoader: true
                })
                const formData = new FormData()
                formData.append('upload_type', "images");
                formData.append('file', fileData);
                dispatch(onUploadImage("SET_CLIENT_LOGO", formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const fetchAgencyData = () => {
        let data = {
            id: aId
        }
        dispatch(onGetAgencyData(data, false, false))
    }

    const getIndustry = () => {
        let ep = "list-industry"
        let data = {}
        dispatch(onFetch(ep, data, setIndustry))
    }


    useEffect(() => {
        if (aId && cId) {
            getClientData()
            fetchAgencyData()
        }
    }, [aId, cId])

    useEffect(() => {
        getIndustry()
    }, [])

    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <div className="container">
                    <div className="business-wrap">
                        <div className="productNav nav mb-4">

                            {auth.user.isClient==="0"?<><div className="nav-item"><Link className="nav-link" to="/agency"><i class="fa-sharp fa-solid fa-building me-2"></i>All Agencies</Link></div>
                            <div className="nav-item"><Link className="nav-link" to={`/agency/create-agency?id=${aId}`}><i class="fa-regular fa-building me-2"></i>{agency.name}</Link></div>
                            <div className="nav-item"><Link className="nav-link " to={`/agency/clients?id=${aId}`}><i class="fa-solid fa-users me-2"></i> All Clients</Link></div>
                            <div className="nav-item"><Link className="nav-link active" to={`/agency/create-client?id=${aId}&cId=${cId}`}><i class="fa-solid fa-user me-2"></i>{clientData.name}</Link></div></>:""}
                        </div>

                        <div className="business-top">
                            <h2>Update Client</h2>
                        </div>

                        <div className="create_client_wrapper">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="business-list" style={{ height: "1010px" }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="proImage  ">
                                                    <div className="proImage-holder text-left" style={{ border: "1px solid #e44e4e", }}>
                                                        {loader.imageLoader ? <i className="fa fa-spinner fa-spin" style={{
                                                            color: "#d03e3e", position: 'absolute',
                                                            top: ' calc(50% - 8%)',
                                                            left: 'calc(50% - 8%)'
                                                        }} /> : <img src={clientData.image} alt="" />}
                                                        <div className="custom-file">
                                                            <FaRegEdit />
                                                            <input
                                                                className="custom-file-input cursor-pointer"
                                                                type="file"
                                                                onChange={handleImage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form className="" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="inpLabelWrap ">
                                                            <span className="labelTxt">Name</span>
                                                            <div className="inpLabel">
                                                                <label>Client Name</label>
                                                                <input
                                                                    className="inpLabel-inp"
                                                                    placeholder="Enter Client Name"
                                                                    type="text"
                                                                    value={clientData.name}
                                                                    name="name"
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="inpLabelWrap">
                                                            <span className="labelTxt">Current Email Adress</span>
                                                            <div className="inpLabel">
                                                                <label htmlFor="email">Enter Current Email Adress</label>
                                                                <input
                                                                    className="inpLabel-inp"
                                                                    placeholder="Email"
                                                                    type="email"
                                                                    name="email"
                                                                    value={clientData.email}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    {auth.user.isClient==="0"?<div className="col-lg-12">
                                                        <div className="inpLabelWrap">
                                                            <span className="labelTxt">Earning</span>
                                                            <div className="inpLabel">
                                                                <label >Enter Earning</label>
                                                                <input
                                                                    className="inpLabel-inp"
                                                                    placeholder="Earning"
                                                                    type="text"
                                                                    name="earning"
                                                                    value={clientData.earning}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>:""}
                                                    {auth.user.isClient==="0"?<div className="col-lg-12">
                                                        <div className="inpLabelWrap">
                                                            <span className="labelTxt">Frequency</span>
                                                            <div className="inpLabel">
                                                                <label>Enter Frequency</label>
                                                                <select
                                                                    className="inpLabel-inp"
                                                                    name="frequency"
                                                                    value={clientData.frequency}
                                                                    onChange={handleChange}
                                                                    required
                                                                >
                                                                    <option value="daily">Daily</option>
                                                                    <option value="weekly">Weekly</option>
                                                                    <option value="fort_nightly">Fort Nightly</option>
                                                                    <option value="monthly">Monthly</option>
                                                                    <option value="yearly">Yearly</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>:""}
                                                </div>

                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Company</span>
                                                    <div className="inpLabel">
                                                        <label>Enter Company</label>
                                                        <input
                                                            className="inpLabel-inp"
                                                            placeholder="Company"
                                                            type="text"
                                                            name="company"
                                                            value={clientData.company}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="inpLabelWrap">
                                                <span className="labelTxt">Industry</span>
                                                    <div className="inpLabel">
                                                        <label>Industry</label>
                                                        <select
                                                            className="inpLabel-inp"
                                                            name='industry'
                                                            value={clientData.industry}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option style={{ fontWeight: "bold", fontSize: 15, color: "#444242" }} value="">Select Industry</option>
                                                            {industry.length > 0 ?
                                                                industry.map((curElem, index) => {
                                                                    return (
                                                                        <option className="text-capitalize" key={index} value={curElem.id}>{curElem.name}</option>
                                                                    )
                                                                })
                                                                : ""}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-12 p-0">
                                                    <div className="modBox mt-4 text-start">
                                                        <div className="inpLabel mt-0 d-flex align-items-center justify-content-between">
                                                            <p style={{ color: "#ff6363", fontSize: "12px", fontWeight: "600" }} htmlFor="">Email Credentials to this client.</p>

                                                            <div className="switch_wrapper">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        name='isEmail'
                                                                        checked={clientData.isEmail}
                                                                        onChange={(e) => handleChange(e)}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="modBox mt-4">
                                                    <div className="inpLabel mt-0 align-items-center d-flex justify-content-between">
                                                        <label htmlFor="">Mode</label>
                                                        <div className="d-flex mode_wrap">
                                                            <label className="switch flex ">
                                                                <input
                                                                    type="checkbox"
                                                                    name='mode'
                                                                    onChange={handleChange}
                                                                    checked={clientData.mode === "0" ? true : false}
                                                                />
                                                                <span className="slider round"></span>
                                                                <span className="slClose">Inactive</span>
                                                                <span className="slOpen">Active</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="btn-sec text-center">
                                                    <button type="submit" className="demoLink">{loader.submitLoader ? <> Updating Client <i className="fa fa-spinner fa-spin mx-1"  />  </> : "Update Client"}</button>
                                                </div>



                                            </form>


                                        </div >
                                    </div >
                                </div >
                                <div className="col-md-8">
                                    <div className="business-list business_integration p-0">
                                        <div className="row">
                                            <div className="col-md-12 pb-5">
                                                <Integration />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >

                        <div className="business-top">
                            <h2>Update Password</h2>
                        </div>
                        <hr style={{ opacity: "1", color: "var(--border-color)" }} />

                        <form className="business-list" onSubmit={handlePassword}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inpLabelWrap">
                                        <span className="labelTxt">Password</span>
                                        <div className="inpLabel">
                                            <label htmlFor="password">Enter Password</label>
                                            <input
                                                className="inpLabel-inp"
                                                placeholder="Password"
                                                type="Password"
                                                name="password"
                                                value={pass.password}
                                                onChange={handleChangePass}
                                                onKeyDown={(event) => {
                                                    if (event.code === "Space") {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  minLength={6}
                                                maxLength={32}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="inpLabelWrap">
                                        <span className="labelTxt">Confirm Password</span>
                                        <div className="inpLabel">
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <input
                                                className="inpLabel-inp"
                                                placeholder="Confirm Password"
                                                type="Password"
                                                name="confirmPassword"
                                                value={pass.confirmPassword}
                                                onChange={handleChangePass}
                                                onKeyDown={(event) => {
                                                    if (event.code === "Space") {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                required
                                                minLength={6}
                                                maxLength={32}
                                            />
                                            <div style={{ color: "red", paddingLeft: "5px", fontSize: "13px" }}>
                                                {msg}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="btn-sec text-right">
                                <button type="submit" className="demoLink">{loader.passwordLoader ? <> Updating Password <i className="fa fa-spinner fa-spin mx-1" />  </> : "Update Password"}</button>
                            </div>
                        </form>

                        <CreateResources
                            aId={aId}
                            cId={cId}
                        />

                    </div >
                </div >
            </>
    )
}

export default CreateClients;