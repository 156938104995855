import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchTransition } from '../../../../Actions/VideoAction';
import TransCard from './TransCard';

const Transition = ({ slideData, transActive }) => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const fetchTransition = () => {
        setLoader(true)
        dispatch(onFetchTransition(setData, setLoader))
    }

    useEffect(() => {
        if (transActive) {
            fetchTransition()
        }
    }, [transActive])

    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Transition</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <ul>
                                {data.length > 0 ?
                                    data.map((curElem, index) => {
                                        if (curElem.image !== "") {
                                            return (
                                                <li key={index} className="col-6" >
                                                    <TransCard
                                                        curElem={curElem}
                                                        slideData={slideData}
                                                    />
                                                </li>
                                            )
                                        }
                                    }) : ''}
                            </ul>
                            {
                                loader ?
                                    <div className="col-md-12">
                                        <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: "#ff6363" }} /></h4>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transition

