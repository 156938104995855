import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onCheckedToggle, onFetchLanding, onUpdateLanding } from "../../Actions/ClientsAction";
import HeaderSection from "./HeaderSection";
import AddonDomain from "./AddonDomain";
import VideoSection from "./VideoSection";
import BenifitSection from "./BenifitSection";
import AboutSection from "./AboutSection";
import TeamSection from "./TeamSection";
import ContactSection from "./ContactSection";
import FooterSection from "./FooterSection";
import { IoMdArrowRoundBack } from "react-icons/io"

const EditLandingPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lpId = queryString.parse(location.search).id
    const landingData = useSelector(state => state.client.landing)

    const [loader, setLoader] = useState({
        submitLoader: false,
        pageLoader: true
    })

    const [addon, setAddon] = useState(false)

    const fetchLandingData = () => {
        let data = {
            id: lpId
        }
        dispatch(onFetchLanding(data, loader, setLoader))
    }

    const handleChecked = (e) => {
        const { name, checked } = e.target
        if (name === "addon") {
            if (addon) {
                setAddon(false)
            } else {
                setAddon(true)
            }
        }
        dispatch(onCheckedToggle(name, checked))
    }

    const handleBackBtn = () => {
        if (landingData.agencyId && landingData.clientId) {
            navigate(`/agency/create-client?id=${landingData.agencyId}&cId=${landingData.clientId}`)
        }
    }

    const onCopyLink = () => {
        var copyText = document.getElementById("liveLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    const handleSubmit = () => {
        let data = { ...landingData }
        delete data.domain
        setLoader({
            ...loader,
            submitLoader: true
        })
        dispatch(onUpdateLanding(data, navigate, loader, setLoader))
    }

    useEffect(() => {
        if (lpId) {
            fetchLandingData()
        }
    }, [lpId])

    useEffect(()=>{
      if(landingData.domain.enable){
        setAddon(landingData.domain.enable)
      }
    },[landingData])

    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Landing Page</h2>
                            <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                        </div>

                        <div className="business-list">
                            <div className="inpLabelWrap mt-0">
                                <span className="labelTxt">Live Link</span>
                                <div className="inpLabel">
                                    <label htmlFor="liveLink">Live Link</label>
                                    <input
                                        className="inpLabel-inp"
                                        value={landingData.liveLink}
                                        type="text"
                                        readOnly
                                        id="liveLink"
                                    />
                                    <span className="inpBtn-icon cursor-pointer" onClick={onCopyLink}>
                                        <FiCopy /> <span className="pl-1">Copy</span>
                                    </span>
                                </div>
                            </div>

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Add Your Domain</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleChecked(e)}
                                            name="addon"
                                            checked={addon}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                addon ?
                                    <AddonDomain />
                                    : ""
                            }
                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Header Section Settings</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="header"
                                            checked={landingData.headers.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.headers.enable ?
                                    <HeaderSection />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Video Section</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="video"
                                            checked={landingData.videos.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round">
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.videos.enable ?
                                    <VideoSection />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Benefit Section Settings</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="benefit"
                                            checked={landingData.benefits.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.benefits.enable ?
                                    <BenifitSection />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">About Section Settings</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="about"
                                            checked={landingData.about.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.about.enable ?
                                    <AboutSection />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Team Section Settings</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="team"
                                            checked={landingData.team.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.team.enable ?
                                    <TeamSection />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Contact Us Section Settings</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="contact"
                                            checked={landingData.contact.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.contact.enable ?
                                    <ContactSection
                                        aId={landingData.agencyId}
                                        cId={landingData.clientId}
                                    />
                                    : ""
                            }

                            <div className="inpLabel d-flex align-items-center justify-content-between line">
                                <label for="check-domain">Footer Section</label>
                                <div className="d-flex">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="footer"
                                            checked={landingData.footer.enable}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                landingData.footer.enable ?
                                    <FooterSection />
                                    : ""
                            }

                            <div className="btn-sec text-right">
                                <button onClick={handleSubmit} className="demoLink">{loader.submitLoader ? <>Submiting <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</button>
                            </div>


                        </div>

                    </div>
                </div>
            </>
    )
}

export default EditLandingPage;