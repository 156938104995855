import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { onFetchFiles, onUploadFileVideo } from '../../../Actions/VideoAction';
import Alert from '../../Alert';
import FileCard from './FileCard';


const UploadComponent = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const video = useSelector(state => state.video)
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    const [uploadImages, setUploadImages] = useState([])
    const [uploadVideos, setUploadVideos] = useState([])
    const [dataFile, setDataFiles] = useState([])
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)


    const handleCall = (e, type) => {
        const formData = new FormData()
        formData.append('upload_type', type)
        formData.append('agencyId', video.agencyId)
        formData.append('clientId', video.clientId)
        formData.append('file', e.target.files[0])
        dispatch(onUploadFileVideo(formData, fetchFiles, type, setPercent, setLoader))
    }

    const onInputFile = (e) => {
        let type
        if (props.type === "media") {
            let allowedSize = 5000000;
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].type !== "video/mp4") {
                    type = "images"
                }
                else {
                    type = "video"
                    allowedSize = 20000000;
                }
                if (allowedSize >= e.target.files[0].size) {
                    setLoader(true)
                    handleCall(e, type)
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: 'Oops...',
                        text: "Max allowed size for image 5MB for video 20MB!",
                        confirmButtonColor: "#d03e3e"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "You have Selected Invalid File Type!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        }
        else if (props.type === "music") {
            let allowedExt = ['audio/mpeg'];
            let allowedSize = 5000000
            type = "music"
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    setLoader(true)
                    handleCall(e, type)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: 'Oops...',
                        text: "Max allowed size is 5MB!",
                        confirmButtonColor: "#d03e3e"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "You have Selected Invalid File Type!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        }
        else if (props.type === "voiceover") {
            let allowedExt = ['audio/mpeg'];
            let allowedSize = 5000000

            type = "voiceover"
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    setLoader(true)
                    handleCall(e, type)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: 'Oops...',
                        text: "Max allowed size is 5MB!",
                        confirmButtonColor: "#d03e3e"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "This file type is not valid!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        }


    }

    const fetchFiles = (type) => {
        let data = {
            type: type,
            agencyId: video.agencyId,
            clientId: video.clientId
        }
        dispatch(onFetchFiles(data, type, setUploadImages, setUploadVideos, setDataFiles))

    }

    useEffect(() => {
        if (props.type === "media") {
            fetchFiles("images")
            fetchFiles("video")
        } else if (props.type === "voiceover") {
            fetchFiles("voiceover")
        } else if (props.type === "music") {
            fetchFiles("music")
        }
    }, [props.type]);

    return (
        <div className='row'>
            <Alert />
            <div className='col-md-12 mt-4'>
                <div className="tabSearch">
                    <button className="demoLink btn-block mt-0 text-capitalize"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload {props.type}</button>
                    <input className="absInp" type="file" onChange={(e) => onInputFile(e)} />
                </div>
                {
                    loader ?
                        <div className="mt-3 text-center" style={{ color: "#d54242", fontSize: 25 }}>
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                        : ''
                }
            </div>
            <div className="innertab-scroll" id="scrollableMediaImage" >
                <div className="row">
                    
                    <div className="mediaList nn">
                        <ul>
                            <FileCard
                                type={props.type}
                                data={props.type === "media" ? uploadImages : dataFile}
                                mediaType="image"
                                tabType={props.tabType}
                                fetchFiles={fetchFiles}
                                selectedSlideIndex={props.selectedSlideIndex}
                                selectedLayerIndex={props.selectedLayerIndex}
                                slectedslide={props.slectedslide}

                            />
                            {props.type === "media" ?
                                <FileCard
                                    type={props.type}
                                    data={props.type === "media" ? uploadVideos : dataFile}
                                    mediaType="video"
                                    tabType={props.tabType}
                                    fetchFiles={fetchFiles}
                                    selectedSlideIndex={props.selectedSlideIndex}
                                    selectedLayerIndex={props.selectedLayerIndex}
                                    slectedslide={props.slectedslide}
                                /> : ''}
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default UploadComponent;
