import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onGetClient, onCreateImage } from "../../Actions/ClientsAction";
import queryString from "query-string";
import { IoMdArrowRoundBack } from "react-icons/io"
import CreateModal from "../Clients/Resources/CreateModal";

const CreateImagePage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)
    const user = useSelector(state => state.auth.user)

    const [keyword, setKeword] = useState("")
    const [imageData, setImageData] = useState(false)
    const [show, setShow] = useState(false);

    const [loader, setLoader] = useState({
        pageLoader: true,
        submitLoader: false,
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submitLoader: true
        })
        let data = {
            keyword: keyword,
            clientId: cId,
            agencyId: aId
        }
        dispatch(onCreateImage(data, setImageData, loader, setLoader))
    }

    const handleBackBtn = () => {
        navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])


    return (

        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Create Image</h2>
                            <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                        </div>

                        <div className="business-list">
                            <div className="proImage withDesc">
                                <div className="proImage-holder">
                                    <img src={clientData.image} alt="" />
                                </div>
                                <div className="proImage-txt">
                                    <p><strong>{clientData.name}</strong></p>
                                    <p>{clientData.email}</p>
                                    <p>{clientData.company}</p>
                                </div>
                            </div>
                        </div>

                        <div className="business-list">

                            <div className="list-template">
                                <form className="row" onSubmit={handleSubmit}>
                                    <div className="col-lg-9">
                                        <div className="inpLabelWrap mt-0">
                                            <span className="labelTxt">Enter a keyword to generate AI Image</span>
                                            <div className="inpLabel">
                                                <label htmlFor="">Keyword</label>
                                                <input className="inpLabel-inp"
                                                    placeholder="Enter keyword"
                                                    type="text"
                                                    value={keyword}
                                                    onChange={(e) => setKeword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="alert alert-info mt-4">
                                            <strong>NOTE:</strong> Please note that the AI can take upto 5 minutes for content to be generated. Please wait and do not go back or close this window.
                                        </div>
                                    </div>
                                    <div className="btn-sec text-right col-lg-3 ">
                                        <button className="demoLink" type="submit">
                                            {loader.submitLoader ? <> Generating <i className="fa fa-spinner fa-spin mx-1" /> </> : `Generate`}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {imageData ? <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" >
                            <div className="list-template-wrap"  >
                                <div
                                    style={{ border: "2px solid #ff6363" }}
                                    className="create_Image">
                                    <img src={imageData} alt="" />
                                    <div className="list-template-btn">
                                        <div>
                                            <span className="demoLink" onClick={handleShow}>Edit</span>
                                        </div>
                                    </div>
                                </div>
                                <h6 className='text-capitalize'>AI Generated Image</h6>
                            </div>
                        </div> : null}
                    </div>

                    <CreateModal
                        show={show}
                        handleClose={handleClose}
                        type="image"
                        aId={aId}
                        cId={cId}
                        imageData={imageData}
                    />
                    {/* <ImageEditModal
                        handleClose={handleClose}
                        show={show}
                        image={imageData}
                    /> */}
                </div>
            </>
    )
}

export default CreateImagePage;