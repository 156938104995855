import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";
import { loadUser } from "./AuthAction";

export const onCreateCampaign =
  (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          if (data.type === "blog") {
            navigate(
              `/create-blog?id=${res.data}&aId=${data.agencyId}&cId=${data.clientId}`
            );
          } else {
            navigate(
              `/agency/create-topic?aId=${data.agencyId}&cId=${data.clientId}&id=${res.data}`
            );
          }
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
      });
  };

export const onCreateBlogCampaign =
  (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign-blog", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(
            `/agency/create-blog?aId=${data.agencyId}&cId=${data.clientId}&id=${res.data}`
          );
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
      });
  };

export const onGetCampaign =
  (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: "SET_VIDEO_CAMPAIGN_INFO", payload: res.data[0] });
        }
        setLoader({
          ...loader,
          pageLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader({
          ...loader,
          pageLoader: false,
        });
      });
  };

export const onSubmitTopic =
  (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-video", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setState(res.data);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          getTopicLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
        setLoader({
          ...loader,
          getTopicLoader: false,
        });
      });
  };

export const onSelectTopic =
  (data, navigate, loader, setLoader, setShowScript) =>
    (dispatch, getState) => {
      commonAxios("create-script", data, dispatch, getState().auth.token)
        .then((res) => {
          if (res.status) {
            if (navigate) {
              navigate(`/agency/video-script?id=${res.data}`);
            } else {
              setShowScript(true);
            }
          } else {
            dispatch(setAlert(res.msg, "danger"));
          }
          setLoader({
            ...loader,
            chooseLoader: false,
            index: -1,
          });
        })
        .catch((err) => {
          dispatch(setAlert(err.msg, "danger"));
          console.log(err);
          setLoader({
            ...loader,
            chooseLoader: false,
            index: -1,
          });
        });
    };

export const onFetchScript =
  (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-script", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setState(res.data[0]);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          pageLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
        setLoader({
          ...loader,
          pageLoader: false,
        });
      });
  };

export const onSaveScript =
  (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-script", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          save: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
        setLoader({
          ...loader,
          save: false,
        });
      });
  };

export const onGenerateSlides =
  (data, navigate, loader, setLoader, videoId) => (dispatch, getState) => {
    commonAxios("save-script", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/editor?id=${videoId}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          generate: false,
        });
      })
      .catch((err) => {
        setLoader({
          ...loader,
          generate: false,
        });
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
      });
  };

// blog Action

export const onCreateBlog =
  (data, setStatus, setLoader) => (dispatch, getState) => {
    commonAxios("create-blog", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setStatus(res.msg);
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onSaveBlog =
  (data, setSaveLoader, navigate, aId, cId) => (dispatch, getState) => {
    commonAxios("update-blog", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setSaveLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg));
        setSaveLoader(false);
      });
  };

export const fetchBlog = (data, setBlog, setLoader) => (dispatch, getState) => {
  commonAxios("view-blog", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setBlog({
          title: res.data[0].savedTitle,
          article: res.data[0].savedContent,
          aiPresent: res.data[0].aiContent
        });
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
      setLoader(false);

    });
};

//Press action

export const onCreatePressCampaign =
  (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios(
      "create-campaign-press-release",
      data,
      dispatch,
      getState().auth.token
    )
      .then((res) => {
        if (res.status) {
          navigate(
            `/agency/create-press?aId=${data.agencyId}&cId=${data.clientId}&id=${res.data}`
          );
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
      });
  };

export const onCreatePress =
  (data, setStatus, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-press-release", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setStatus(res.msg);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          createLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg));
        setLoader({
          ...loader,
          createLoader: false,
        });
      });
  };

export const fetchPress = (data, setShow, setBlog, loader, setLoader, setContent, setId, id, keyword, setKeyWord) => (dispatch, getState) => {
  commonAxios("view-press-release", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (res.data[0].savedContent === "") {
          setBlog(res.data[0].aiContent);
        } else {
          setBlog(res.data[0].savedContent);
        }
        setId({
          ...id,
          aId: res.data[0].agencyId,
          cId: res.data[0].clientId,
        });
        setContent(res.data[0].savedContent);
        if (res.data[0].aiContent !== "") {
          setShow(true);
          setKeyWord({
            ...keyword,
            category: res.data[0].category,
            topic: res.data[0].topic,
            company: res.data[0].companyName
          });
          // setKeword(res.data[0].keyword)
        }
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader({
        ...loader,
        createLoader: false,
      });
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onSavePress =
  (data, loader, setLoader, navigate, id) => (dispatch, getState) => {
    commonAxios("update-press-release", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/create-client?id=${id.aId}&cId=${id.cId}`);
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          saveLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg));
        setLoader({
          ...loader,
          saveLoader: false,
        });
      });
  };

export const onCreateBusinessCard =
  (data, navigate, loader, setLoader,) => (dispatch, getState) => {
    commonAxios("create-business-card", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/${data.type !== "invoice" ? "image-editor" : "invoice-editor"}?aId=${data.agencyId}&cId=${data.clientId}&id=${res.data}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          submitLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          submitLoader: false,
        });
      });
  };

export const onFecthCards =
  (data, setLoader, setDataVal) => (dispatch, getState) => {
    commonAxios("view-business-card", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          let obj = res.data[0];
          if (obj.type === "invoice") {
            dispatch({ type: "SET_INVOICE_DATA", payload: obj })
          }
          else {
            obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" : "*"`);
            setDataVal(obj);
          }
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onSaveImage =
  (data, navigate, setLoader, aId, cId) => (dispatch, getState) => {
    commonAxios("save-business-card", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onGenerateImage = (data, state, setState, setImgEdit, setLoader) => (dispatch, getState) => {
  commonAxios("save-base", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setState({
          ...state,
          image: res.data
        })
        setImgEdit(false)
      } else {
        dispatch(setAlert((res.msg, "danger")))
      }
      setLoader(false)
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"))
      setLoader(false)
      console.log(err)
    })
}

export const onFacebookFetch = (data, url, setData, active) => (dispatch, getState) => {
  let obj = { ...data }
  if (url === "facebook-page-fetch") {
    obj = {
      agencyId: data.agencyId,
      clientId: data.clientId,
      accountId: data.facebookAccountId
    }
  }
  commonAxios(url, obj, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let arr = res.data
        if (active) {
          let arr = []
          arr.forEach((curElem) => {
            let obj = {
              label: curElem.name,
              value: curElem.id,
            }
            arr.push(obj)
          })
        }
        setData(arr);
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
    }).catch((err) => {
      console.log(err)
      dispatch(setAlert(err.msg, "danger"))
    })
}


export const onFacebookCreate = (data, url, setData, loader, setLoader) => (dispatch, getState) => {
  commonAxios(url, data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setData(res.data);
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader({
        ...loader,
        modalSubmit: false,
      });
    })
    .catch((err) => {
      setLoader({
        ...loader,
        modalSubmit: false,
      });
      console.log(err);
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onSubmitAdset = (data, setLoader, handleClose, state, setState, adSets, setAdSets) => (dispatch, getState) => {
  commonAxios("facebook-create-ad-sets", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"))
        handleClose()
        let data = [...adSets]
        data = data.concat(res.data)
        setAdSets(data)
        setState({
          ...state,
          adSetsId: res.data.id
        })
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      dispatch(setAlert(err.msg, "danger"))
      setLoader(false)
    })
}

export const onLoadFbData = (ep, data, navigate, type, setLoader) => (dispatch, getState) => {
  commonAxios(ep, data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        navigate(
          `/agency/create-${type === "fb" ? "facebook" : "google"}?aId=${data.agencyId
          }&cId=${data.clientId}&id=${res.data}`
        );
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAlert(err.msg, "danger"));
      setLoader(false);
    });
};

export const onGetFbData = (data, setState) => (dispatch, getState) => {
  commonAxios("get-facebook-ads-data", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = {
          ...res.data[0],
          captionLink: "https://pages.clickagency.io/",
          buttonUrl: "https://pages.clickagency.io/",
        };
        setState(obj);
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onGetGleData = (data, setState) => (dispatch, getState) => {
  commonAxios("get-google-ads-data", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = {
          ...res.data[0],
          captionLink: "https://pages.clickagency.io/",
          buttonUrl: "https://pages.clickagency.io/",
        };
        setState(obj);
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onFetchData = (data, setState) => (dispatch, getState) => {
  commonAxios("facebook-search-data", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let arr = []
        res.data.forEach((curElem, index) => {
          let obj = {
            label: curElem.name,
            value: curElem.key ? curElem.key : curElem.id,
            id: curElem.id ? curElem.id : index + 1
          }
          arr.push(obj)
        })
        setState(arr)
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
    }).catch((err) => {
      console.log(err)
      dispatch(setAlert(err.msg, "danger"))
    })
}


export const onPublishFacebook = (data, loader, setLoader) => (dispatch, getState) => {
  commonAxios("facebook-create-ad", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"))
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader({
        ...loader,
        submitLoader: false
      })
    }).catch((err) => {
      setLoader({
        ...loader,
        submitLoader: false
      })
      console.log(err)
      dispatch(setAlert(err.msg, "danger"))
    })
}
export const onCreateLead = (data, navigate, setLoader) => (dispatch, getState) => {
  commonAxios("create-campaign-lead-magnet", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        navigate(`/agency/create-lead-magnet?aId=${data.agencyId}&cId=${data.clientId}&id=${res.data}`)
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      setLoader(false)
      dispatch(setAlert(err.msg, "danger"))
    })
}


export const onSubmitKeyLead = (data, setState, loader, setLoader) => (dispatch, getState) => {
  commonAxios("create-lead-magnet", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = {
          id: data.id,
        };
        dispatch(onGetLead(obj, setState, loader, setLoader));
      } else {
        dispatch(setAlert(res.msg, "danger"));
        setLoader({
          ...loader,
          submitLoader: false,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      setLoader({
        ...loader,
        submitLoader: false,
      });
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onGetLead =
  (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("view-lead-magnet", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setState(res.data[0]);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          submitLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader({
          ...loader,
          submitLoader: false,
        });
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const onUpdateLead = (data, loader, setLoader, sendPdf, navigate,aId, cId) => (dispatch, getState) => {
  commonAxios("update-lead-magnet", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (sendPdf) {
          let obj = {
            id: data.id
          }
          commonAxios("generate", obj, dispatch, getState().auth.token)
            .then((res) => {
              if (res.status) {
                window.open(res.data, "_blank")
                navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
                console.log("first")
              } else {

              }
            }).catch((err) => {
              dispatch(setAlert(err.msg, "danger"));
            })

        } else {
          dispatch(setAlert(res.msg, "success"));
        }
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader({
        ...loader,
        updateLoader: false,
        saveLoader: false
      });
    })
    .catch((err) => {
      console.log(err);
      setLoader({
        ...loader,
        updateLoader: false,
        saveLoader: false
      });
      dispatch(setAlert(err.msg, "danger"));
    });
};





export const spendUserCredit = () => (dispatch, getState) => {
  let user = getState().auth.user;
  let credits = Number(user.credits) - 1;
  let data = {
    userId: user.id,
    credits: credits,
  };
  commonAxios("credits-update", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(loadUser());
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onGenerateAiImage =
  (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-image", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          // dispatch(spendUserCredit());
          setState({
            ...state,
            image: res.data,
          });
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          aiLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader({
          ...loader,
          aiLoader: false,
        });
        dispatch(setAlert(err.msg, "danger"));
      });
  };

//Invoice Action

export const onCreateInvoice =
  (data, navigate, loader, setLoader, id) => (dispatch, getState) => {
    commonAxios("create-business-card", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          navigate(`/agency/${data.type !== "invoice" ? "image-editor" : "invoice-editor"}?aId=${data.agencyId}&cId=${data.clientId}&id=${id}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          submitLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          submitLoader: false,
        });
      });
  };

export const onInvoiceSave1 = (data, setLoader, loader, navigate, aId, cId) => (dispatch, getState) => {
  commonAxios("save-business-card", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader({
        ...loader,
        saveLoader: false
      })
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"))
      setLoader({
        ...loader,
        saveLoader: false
      })
    })
}


// No Axios Here


export const unmountInvoice = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_INVOICE" })
}

export const onSetInvoice = (data) => (dispatch) => {
  dispatch({ type: "SET_DATA", payload: data })
}

// export const onSetInvoiceTableData = (data,table) => (dispatch) => {
//   const value ={data,table}
//   dispatch({type : "SET_DATA_INVOICE", payload : value})
// } 

// export const addHeadingData = (type, value) => (dispatch) => {
//   const data = {type, value}
//   dispatch({type : "ADD_HEADING_DATA", payload : data})
// }

// export const addRowsData = (data) => (dispatch) => {
//   dispatch({type : "ADD_ROWS_DATA", payload : data})
// }

// export const addPayInformation=(data)=>(dispatch)=>{
//    dispatch({type:"ADD_PAY_INFORMATION",payload:data})
// }

export const onSetInvoiceRow = (data) => (dispatch) => {
  dispatch({ type: "ON_UPDATE_ROW", payload: data })
}

export const onSetInvoiceTable = (data) => (dispatch) => {
  dispatch({ type: "ON_UPDATE_TABLE", payload: data })
}

export const onUpdateInvoiceData = (name, value) => (dispatch) => {
  let data = { name, value }
  dispatch({ type: "ON_UPDATE_INVOICE_DATA", payload: data })
}