import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import facebookIcon from "../../../images/facebook.svg";
import youtubeIcon from "../../../images/youtube.svg"
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import SocialIcons from './SocialIcons';
import { onPublishVideo } from '../../../Actions/ClientsAction';

const PublishModal = ({ vUrl, show, handleClose }) => {
    const socials = useSelector(state => state.agency.integration)
    const dispatch = useDispatch()
    const [currSocial, setCurrSocial] = useState([]);
    const [selectIcon, setSelectIcon] = useState(false);
    const [loader, setLoader] = useState(false)
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: "",
        integration_id: '',
        type: '',
        network: ''
    })

    const currentSocial = (social, type, icon) => {
        if (social.length > 0) {
            setCurrSocial(social);
            setSelectIcon(icon);
            setPostData({
                ...postData,
                type: type,
                integration_id: social[0].id,
                network: icon,
            });
        }
    }


    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }
console.log(vUrl)
    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let data  = {...postData}
        data.video_url = vUrl
        dispatch(onPublishVideo(postData.type, data, handleClose, setLoader))
    }



    useEffect(() => {
        if (!show) {
            setCurrSocial([])
            setSelectIcon(false);
            setPostData({
                ...postData,
                title: '',
                description: '',
                integration_id: '',
                type: '',
                network: ''
            })
        }
    }, [show])

    return (

        <Modal className="theme-modal public_modal  px-2" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                <h3 className='text-capitalize text-center mb-5'>Publish Video</h3>
                <ul className='d-flex justify-content-evenly align-items-center px-1' >
                    <li onClick={() => currentSocial(socials.facebook, 'upload-to-facebook', 'facebook', vUrl)} className='social_hover text-center'>
                        <SocialIcons
                            icon={facebookIcon}
                            status={socials.facebook.length > 0 ? true : false}
                            name="Facebook"
                            selectIcon={selectIcon === "facebook" ? "select-social" : ''}
                        />
                    </li>
                    <li
                        onClick={() => currentSocial(socials.youtube, 'upload-to-youtube', 'youtube', vUrl)} className='social_hover text-center'
                    >
                        <SocialIcons
                            icon={youtubeIcon}
                            status={socials.youtube.length > 0 ? true : false}
                            name="Youtube"
                            selectIcon={selectIcon === "youtube" ? "select-social" : ''}
                        />
                    </li>

                </ul>

                <div className="form-body mt-4">
                    <form onSubmit={(e) => onFormSubmit(e)}>

                        <div className="inpLabelWrap mt-0">
                            <div className="inpLabel">
                                <label>Title</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Enter Title"
                                    type="text"
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    name="title"
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap ">
                            <div className="inpLabel">
                                <label>Description</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Enter Description"
                                    type="text"
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    name="description"
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap">
                            <div className="inpLabel">
                                <label>Account Type</label>
                                <select
                                    className="inpLabel-inp"
                                    required
                                    name="integration_id"
                                    onChange={(e) => onInputChange(e)}
                                >
                                    <option value={""}>Select Account</option>
                                    {
                                        currSocial.length > 0 ?
                                            currSocial.map((item, index) => {
                                                return (
                                                    <option key={index} style={{ backgroundColor: "#000", color: "#fff" }} value={item.id}>{item?.username}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                            </div>
                        </div>


                        <div className="text-center mt-4">
                            <button type="submit" className="demoLink" disabled={loader}>
                                <span>
                                    {loader ? <>
                                        Publishing
                                        <i className="fa fa-spinner fa-spin mx-2" />  </> : 'Publish'}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default PublishModal