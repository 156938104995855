import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import iconWrite from "../../images/icon-write.svg";
import iconRight from "../../images/icon-arrow.svg";
import modalCloseIcon from "../../images/modal-close.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../Actions/AlertAction";
import { appName } from "../../Global/Global";
import { fetchDashboard } from "../../Actions/AgencyAction";
import { onGetClient } from "../../Actions/ClientsAction";
import { onfecthClients } from "../../Actions/ClientsAction";
import Dashboard from "./Dashboard";
// import ContentModal from "../CreateBusiness/ContentModal";

const DashboardHead = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const course = useSelector((state) => state.course);
  const rebrandData = useSelector((state) => state.rebrand.data);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [memberShip, setMemberShip] = useState([]);
  const [state, setState] = useState({
    client: "",
    revenue: "",
  });

  const fetchDashboarData = () => {
    setLoader(true);
    let data = {};
    dispatch(fetchDashboard(data, state, setState, setLoader));
  };



  useEffect(() => {
    fetchDashboarData();
  }, []);


  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"));
    }
  }, [auth.user]);

  const handleShow2 = () => {
    if (memberShip.length === 1 && memberShip[0] === "premium") {
      if (course.coursesAll.length >= 50) {
        dispatch(setAlert("You can create max courses of 50 only", "danger"));
      } else {
        setShow2(true);
      }
    } else {
      setShow2(true);
    }
  };


  return (
    <>
      <section className="hero-banner">
        {/* <div className="hero-video">
          <video
            src="https://reeelapps-app.s3.us-west-2.amazonaws.com/CLICKAGENCY+AI+BG+VIDEO.mp4"
            autoPlay
            muted
            style={{ width: "100%" }}
            loop
          />
           <iframe
            // src="https://player.vimeo.com/video/807436531?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0"
            src=""
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe> 
        </div> */}
        <div className="hero-banner-txt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3 className="text-uppercase">
                    {rebrandData ? rebrandData.name : appName}
                  </h3>
                  <p>
                    Create powerful ad campaigns and video lead pages which are
                    designed to grab your audience's attention and convert them
                    into leads - with just a few clicks of a button. Your
                    campaigns will be consistent across all channels, from
                    social media to email marketing.
                  </p>
                  {/* <button onClick={handleShow} className="demoLink">
                    <i className="fa fa-play" aria-hidden="true"></i> Watch Demo
                    Video
                  </button> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-right">
                  {auth.user.isClient === "0" ? (
                    <>
                      <Link to="/agency" className="bannerLink">
                        <div className="bannerLink-left">
                          <span>
                            <img alt="" src={iconWrite} />
                          </span>{" "}
                          View Agency
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>
                      <Link to="/clients" className="bannerLink">
                        <div className="bannerLink-left">
                          <span>
                            {loader ? (
                              <i className="fa fa-spinner fa-spin mx-1" />
                            ) : (
                              state.client
                            )}
                          </span>{" "}
                          View Clients
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>

                      <Link to="/integration" className="bannerLink notClick">
                        <div className="bannerLink-left">
                          <span>
                            $
                            {loader ? (
                              <i className="fa fa-spinner fa-spin mx-1" />
                            ) : (
                              state.revenue
                            )}
                          </span>{" "}
                          Monthly revenue
                        </div>
                        <div className="bannerLink-right">
                          <img alt="" src={iconRight} />
                        </div>
                      </Link>
                    </>
                  ) : (
                    <Link
                      to={`/agency/create-client?id=${auth.user.agencyId}&cId=${auth.user.id}`}
                      className="bannerLink"
                    >
                      <div className="bannerLink-left">
                        <span>
                          <img alt="" src={iconWrite} />
                        </span>{" "}
                        View Details
                      </div>
                      <div className="bannerLink-right">
                        <img alt="" src={iconRight} />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal className="VideoModal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            <img alt="" src={modalCloseIcon} />
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe
                src="https://player.vimeo.com/video/808640412?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false;"
                allowFullScreen="true"
                allow="autoplay,fullscreen,picture-in-picture"
                title="Player for FastPaid Intro"
                data-ready="true"
                tabIndex="-1"
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <ContentModal
                show={show2}
                handleClose={handleClose2}
            /> */}
    </>
  );
};

export default DashboardHead;
