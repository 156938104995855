import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { onGenerateAiImage, onGetFbData, onPublishFacebook } from '../../Actions/ResourceAction'
import ImageEditor from '../ImageEditor/ImageEditor'
import ImagesModal from '../LandingPage/Modals/ImagesModal'
import { setAlert } from "../../../src/Actions/AlertAction";
import { MdOutlineKeyboardBackspace } from 'react-icons/md'

const AddSetup = ({ facebookAccountId, adAccountId, adSetId, pageId }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const id = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)
    const user = useSelector(state => state.auth.user)

    const [state, setState] = useState({
        keyword: "",
        name: "",
        title: "",
        description: "",
        image: "",
        captionLink: "",
        buttonUrl: "",
        buttonType: "LEARN_MORE",
        buttonText: ""
    })

    const [ImgEdit, setImgEdit] = useState(false)

    const [fbModle, setFbModle] = useState(false);
    const showFbModel = () => setFbModle(true)
    const closeFbModel = () => setFbModle(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loader, setLoader] = useState({
        aiLoader: false,
        submitLoader: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const generateAiImage = () => {
        if (+user.credits > 0) {
            let data = {
                keyword: state.keyword
            }
            setLoader({
                ...loader,
                aiLoader: true
            })
            dispatch(onGenerateAiImage(data, state, setState, loader, setLoader))
        } else {
            dispatch(setAlert("Credit Limit Exceeded", "danger"))
        }

    }

    const fetchFbAddData = () => {
        let data = {
            id: id
        }
        dispatch(onGetFbData(data, setState))
    }
    const handleUpload = (data) => {
        setState({
            ...state,
            image: data.url
        })
    }

    const handleSubmit = () => {
        let data = {
            agencyId: aId,
            clientId: cId,
            pageId: pageId,
            imageUrl: state.image,
            facebookAccountId: facebookAccountId,
            adAccountId: adAccountId,
            adSetId: adSetId,
            name: state.name,
            link: state.captionLink,
            title: state.title,
            description: state.description,
            buttonUrl: state.buttonUrl,
            buttonType: state.buttonType,
            buttonText: state.buttonText
        }
        setLoader({
            ...loader,
            submitLoader: true
        })
        dispatch(onPublishFacebook(data, loader, setLoader))
    }
    useEffect(() => {
        fetchFbAddData()
    }, [])

    return (

        ImgEdit ?
            <div className='pt-2'>
                <MdOutlineKeyboardBackspace
                    size={30}
                    style={{ left: 20, position: "relative", cursor: "pointer", color: "#ff6363" }}
                    title="Back to setup"
                    onClick={() => setImgEdit(false)}
                />
                <ImageEditor
                    stopLoader={true}
                    image={state.image}
                    type="facebook"
                    state={state}
                    setState={setState}
                    setImgEdit={setImgEdit}
                />
            </div>
            :
            <>
                <div className="pt-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="socialTabLeft">
                                <div className="social-tab-img">
                                    <div className="social-tab-img-in">
                                        <img src={state.image} alt="" />
                                    </div>
                                    <span className="noteTxt">Recommended image size: 1080 × 1080 pixels. Recommended image ratio: 1:1. To maximise ad delivery, use an image that contains title or no overlaid text.</span>
                                </div>
                                <div className="d-flex">
                                    <div className="btn-sec mt-0">
                                        <button className="demoLink mt-2" onClick={handleShow}>Upload</button>
                                    </div>

                                    <div className="btn-sec mt-0 me-2">
                                        <button className="demoLink mt-2" onClick={generateAiImage}>{loader.aiLoader ? <>Generating  <i className="fa fa-spinner fa-spin mx-1" /></> : `AI Image`}</button>
                                    </div>

                                    <div className="btn-sec mt-0">
                                        <button className="demoLink mt-2" onClick={() => setImgEdit(true)}>Edit</button>
                                    </div>

                                </div>
                                <div className="alert alert-info mt-4">
                                    <strong>NOTE:</strong> Please note that the AI can take upto 5 minutes for content to be generated. Please wait and do not go back or close this window.
                                </div>
                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Add Name</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Add Name</label>
                                        <input
                                            className="inpLabel-inp"
                                            type="text"
                                            name='name'
                                            placeholder="Enter Add Name"
                                            value={state.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Add Headline</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Add Headline</label>
                                        <input
                                            className="inpLabel-inp"
                                            type="text"
                                            name='title'
                                            placeholder="Enter Headline"
                                            value={state.title}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Add Description</span>
                                    <div className="inpLabel">
                                        <label htmlFor="" style={{ width: "100%", paddingBottom: "10px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #ffbebe", borderLeft: "1px solid #ffbebe", borderRight: "1px solid #ffbebe" }}>Add Description</label>
                                        <textarea
                                            className="inpLabel-inp"
                                            name="description"
                                            placeholder="Add Description"
                                            value={state.description}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Add Button Type</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Add Button Type</label>
                                        <select
                                            className="inpLabel-inp"
                                            name="buttonType"
                                            onChange={handleChange}
                                            value={state.buttonType}
                                        >
                                            <option value="LEARN_MORE" selected>Learn More</option>
                                            <option value="SIGN_UP">Sign Up</option>
                                            <option value="DOWNLOAD">Download</option>
                                            <option value="APPLY_NOW">Apply Now</option>
                                            <option value="CONTACT">Contact</option>
                                            <option value="GET_OFFER">Get Offer</option>
                                            <option value="BOOK_NOW">Book Now</option>
                                            <option value="GET_QUOTE">Get Quote</option>
                                            <option value="CONTACT_US">Contact Us</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Add Button Text</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Add Button Text</label>
                                        <input
                                            className="inpLabel-inp"
                                            type="text"
                                            placeholder="Add Button Text"
                                            name='buttonText'
                                            value={state.buttonText}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Display Caption Link</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Display Caption Link</label>
                                        <input
                                            className="inpLabel-inp"
                                            type="text"
                                            name='captionLink'
                                            placeholder="Display Caption Link"
                                            value={state.captionLink}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Button Redirect URL</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Button Redirect URL</label>
                                        <input
                                            className="inpLabel-inp"
                                            type="text"
                                            name='buttonUrl'
                                            placeholder="Button Redirect URL"
                                            value={state.buttonUrl}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="socialTabRight">
                                <div className="addPrev">
                                    <div className="addPrev-top">
                                        <div className="addPrev-top-left">
                                            <div className="addPrev-top-img">
                                                <img src={clientData.image} alt="" />
                                            </div>
                                            <div className="addPrev-top-txt">
                                                <h6>Daily Deals</h6>
                                                <p>Sponsored <img src={require('../../images/earth.png')} /></p>
                                            </div>
                                        </div>
                                        <div><img src={require('../../images/face-dots.png')} /></div>
                                    </div>
                                    <p className="pt-2">{state.description}</p>
                                    <div className="addPrev-img"><img src={state.image} alt="" /></div>

                                    <div className="addPrev-top alt pt-3">
                                        <div className="addPrev-top-left">
                                            <div className="addPrev-top-txt pl-0">
                                                <p>{state.buttonUrl}</p>
                                                <h6>{state.title}</h6>
                                            </div>
                                        </div>
                                        <div><a className="facebtn" href="">Learn More</a></div>
                                    </div>
                                    <div className='social-Share'>
                                        <ul>
                                            <li><img src={require('../../images/face-like.png')} /></li>
                                            <li><img src={require('../../images/face-comment.png')} /></li>
                                            <li><img src={require('../../images/face-share.png')} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="btn-sec text-right mt-0">
                        <button className="demoLink" onClick={handleSubmit}>{loader.submitLoader ? <>Publishing <i className="fa fa-spinner fa-spin mx-1" /></> : "Publish"}</button>
                    </div>

                    <ImagesModal
                        show={show}
                        handleClose={handleClose}
                        callbackFun={handleUpload}
                        aId={aId}
                        cId={cId}
                    />
                </div>
            </>
    )
}

export default AddSetup