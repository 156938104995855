import React, { useState, useEffect } from 'react'
import { onFetchUserData } from '../../../Actions/VideoAction'
import { useDispatch } from 'react-redux'
import Pagination from '../../Agency/Pagination'
import { BiArrowBack } from 'react-icons/bi'

const UserObtained = (props) => {
    const dispatch = useDispatch()


    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(false)
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });

    const fetchUserData = () => {
        setLoader(true)
        let data = {
            id: props.userIdData
        }
        dispatch(onFetchUserData(data, setUserData, setLoader))
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const handleBack = () => {
        props.userData(false)
    }

    const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
    const currentTodo = userData.slice(indexOfFirstTodo, indexOfLastTodo);


    return (
        <>
            <div className="business-top p-2">
                {/* <h2> User Obtained</h2> */}
                <BiArrowBack style={{ color: "#ed5b5b", fontSize: "xx-large", cursor: "pointer" }} onClick={handleBack} />
            </div>
            <hr style={{ opacity: "1", color: "var(--border-color)" }} />
            <div className="business-list">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bListing">
                            <table className="table businessTable">
                                <thead>
                                    <tr>
                                        <th className='cursor-pointer' >Name</th>
                                        <th className='cursor-pointer' >Email</th>
                                        <th className='cursor-pointer'>Message</th>
                                        <th className='cursor-pointer'>Subject</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTodo.length > 0 ?
                                        currentTodo.map((curElem, index) => {
                                            return (
                                                <tr>
                                                    <td style={{ paddingLeft: "0px" }}>{curElem.name}</td>
                                                    <td>{curElem.email}</td>
                                                    <td>{curElem.message}</td>
                                                    <td>{curElem.subject}</td>
                                                </tr>
                                            )
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            <div className="text-center mt-1">
                                {userData.length === 0 ?
                                    loader ?
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#d03e3e" }} />
                                        :
                                        `"No user obtained found!"` :
                                    ""}
                            </div>

                            <Pagination
                                listData={userData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={[]}
                                loader={loader}
                            />

                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default UserObtained