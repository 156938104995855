import React, { useEffect, useState } from "react";
import youtubeIcon from "../../images/googleIconImg.png"
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import SweetAlertTwo from "../SweetAlertTwo";
import { onDeleteAutoRes } from "../../Actions/ClientsAction";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { saveGoogle } from "../../Actions/AgencyAction";
import { gapi } from 'gapi-script';

const ConnectGoogle = (props) => {
    const location = useLocation()
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    // Sweetalert
    const [info, setInfo] = useState({
        id: -1,
        type: "",
        enable: false
    })
    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
            type : "social"
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAutoRes(data, props.fetchIntegration, Swal))
    }

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }


    const connectYoutube = (code) => {
        let data = JSON.stringify({ code: code, user_id: auth.user.id, agencyId: aId, clientId: cId })
        dispatch(saveGoogle(data, props.fetchIntegration))

    }

    useEffect(() => {
        function start() {
            gapi.client.init({ clientId: "604208618490-mi02nsunigkntsnhp1b45eljdq1ja4mk.apps.googleusercontent.com", scope: '' })
        };
        gapi.load('client:auth2', start);
    });

    return (
        <>
            <div className="connection-box mt-3" id="youtube">


                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={youtubeIcon} width="56px" alt="youtube" />
                    </div>

                    <div className="connection-txt">

                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>Google</h6>
                                                    <p className="m-0">{item?.username} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <GoogleLogin
                                                    clientId="604208618490-mi02nsunigkntsnhp1b45eljdq1ja4mk.apps.googleusercontent.com"
                                                    render={(renderProps) => (
                                                        <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect New Account</button>
                                                    )}
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}
                                                    scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                                    responseType="code"
                                                    accessType="offline"
                                                    prompt="consent"
                                                    cookiePolicy={"single_host_origin"}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                        <div className="youtube-content">
                                            <h6>Google</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <GoogleLogin
                                            clientId="604208618490-mi02nsunigkntsnhp1b45eljdq1ja4mk.apps.googleusercontent.com"
                                            render={(renderProps) => (
                                                <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect</button>
                                            )}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                            responseType="code"
                                            accessType="offline"
                                            prompt="consent"
                                            cookiePolicy={"single_host_origin"}
                                        />
                                    </div>
                                </div>
                        }



                    </div>
                </div>


                <div className="row d-none">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`youtube-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                            <img src={youtubeIcon} width="56px" alt="youtube" />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>Google</h6>
                                                <p className="m-0">{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Google</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <GoogleLogin
                                clientId="604208618490-mi02nsunigkntsnhp1b45eljdq1ja4mk.apps.googleusercontent.com"
                                render={(renderProps) => (
                                    <button onClick={renderProps.onClick} type="button" className="btn-change7">Connect</button>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl"
                                responseType="code"
                                accessType="offline"
                                prompt="consent"
                                cookiePolicy={"single_host_origin"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />
        </>
    )
}

export default ConnectGoogle;