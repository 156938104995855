import { current } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchResourcesList } from '../../../Actions/ClientsAction';
import ListRows from './ListRows';
import Pagination from '../../Agency/Pagination';
import dummyIcon from '../../../images/fav-new.png'
import UserObtained from './UserObtained';
const ResourceListTable = ({ aId, cId,userData,userIdData,userName}) => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const [loader, setLoader] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10
  });

  const [state, setState] = useState({
    name: "",
    type: ""
  })

  const handleSort = (type) => {
    let data = [...list]
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    }
    if (type === "type") {
      if (state.type) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          type: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          type: true,
        });
      }
    }
    setList(data)

  }

  const fetchResourceList = () => {
    let data = {
      agencyId: aId,
      clientId: cId
    }
    setLoader(true)
    dispatch(onFetchResourcesList(data, setList, setLoader))
  }


  const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
  const currentTodo = list.slice(indexOfFirstTodo, indexOfLastTodo);

  useEffect(() => {
    if (aId && cId) {
      fetchResourceList()
    }
  }, [aId, cId])
  return (
    <>
    
    <div className="bListing">
      <table className="table businessTable">
        <thead>
          <tr>
            <th width="150"></th>
            <th className='cursor-pointer' onClick={() => handleSort("name")}>Name</th>
            <th className='cursor-pointer' onClick={() => handleSort("type")}>Type</th>
            <th className='cursor-pointer'>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentTodo.length > 0 ?
            currentTodo.map((curElem, index) => {
              let name = ""
              let logo, url, scId, status, vUrl
              if (curElem.type === "landingPage") {
                name = curElem.name
                logo = curElem.templatesImage
                url = curElem.liveLink
                status = curElem.status

              } else if (curElem.type === "blogArticles") {
                name = curElem.name
                logo = dummyIcon
                status = curElem.status

              } else if (curElem.type === "youtube" || curElem.type === "adds") {
                name = curElem.name
                logo = dummyIcon
                scId = curElem.script ? curElem.script.id : false
                status = curElem.script?  curElem.script.status : 0
                vUrl = curElem.script ? curElem.script.videoUrl : ""

              } else {
                name = curElem.name
                logo = dummyIcon
                status = curElem.status
              }
              return (
                <React.Fragment key={index}>
                  <ListRows
                    id={curElem.id}
                    scriptId={scId}
                    name={name}
                    type={curElem.type}
                    status={status}
                    logo={logo}
                    aId={aId}
                    cId={cId}
                    landingUrl={url}
                    videoUrlData={vUrl}
                    fetchResourceList={fetchResourceList}
                    userData={userData}
                    userIdData={userIdData}
                    userName={userName}
                  />
                </React.Fragment>
              )
            })
            : ""}
        </tbody>
      </table>
      <div className="text-center mt-1">
        {list.length === 0 ?
          loader ?
            <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#d03e3e" }} />
            :
            `"No resources created yet!"` :
          ""}
      </div>

      <Pagination
        listData={list}
        pagination={pagination}
        setPagination={setPagination}
        currentTodo={currentTodo}
        listArr={[]}
        loader={loader}
      />

    </div>
    </>
  )
}

export default ResourceListTable