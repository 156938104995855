import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebookIcon from "../../images/logo-facebook.svg"

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FaSpinner } from "react-icons/fa"
import { Modal } from "react-bootstrap";
import SweetAlertTwo from "../SweetAlertTwo";
import { onDeleteAutoRes } from "../../Actions/ClientsAction";
import { saveFacebook } from "../../Actions/AgencyAction";
import modalCloseIcon from '../../images/modal-close.png';
import queryString from "query-string";
import { useLocation } from "react-router-dom";


const ConnectFacebook = (props) => {

    let location = useLocation()
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });
    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);


    const [info, setInfo] = useState({
        id: -1,
        type: "",
        enable: false
    })
    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
            type : "social"
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAutoRes(data, props.fetchIntegration, Swal))
    }


    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick =  () => {
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
            agencyId: aId,
            clientId: cId,
        };

        dispatch(saveFacebook(data, props.fetchIntegration));
        setDetails({ ...details, openPopup: false });
    };

    const responseFacebook = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );
            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /* Fetch FB Groups */
        try {
            const res = await axios.get(
                "https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <div className="connection-box mt-3" id="facebook">
                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={facebookIcon} width="56px" alt="facebook" />
                    </div>

                    <div className="connection-txt">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>Facebook</h6>
                                                    <p className="m-0">{item?.username} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <FacebookLogin
                                                    appId="1072195363975980" //APP ID NOT CREATED YET
                                                    fields="name,email"
                                                    // scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                                    scope="pages_manage_posts,publish_to_groups,pages_show_list,publish_video"
                                                    callback={(e) => responseFacebook(e)}
                                                    render={renderProps => (
                                                        <button onClick={renderProps.onClick} className="btn-change7">Connect New Account</button>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                        <div className="youtube-content">
                                            <h6>Facebook</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <FacebookLogin
                                            appId="1072195363975980" //APP ID NOT CREATED YET
                                            fields="name,email"
                                            // scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                            scope="pages_manage_posts,publish_to_groups,pages_show_list,publish_video"
                                            callback={(e) => responseFacebook(e)}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                            )}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>


                <Modal className='VideoModal xl' show={details.openPopup} onHide={closeConnectModel} centered >
                    <Modal.Body>
                        <div className="vidClose" onClick={closeConnectModel}><img src={modalCloseIcon} alt="" /></div>
                        <div className='connect-modal'>
                            <h3>Select an account</h3>
                            <hr />
                            <div className="alert alert-info" role="alert">
                                Please note that, you can post into Facebook Pages and Facebook Groups only if you have the administrator rights for that Page or Group. If a Page or Group of yours is not listed which means you might not have the administrator rights for that Page or Group.
                            </div>
                            <div className='row'>
                                <div className='inpLabelWrap mt-0'>
                                    <div className="inpLabel">
                                        <label>Select An Account</label>

                                        <select
                                            className="inpLabel-inp"
                                            value={dropValue}
                                            onChange={handleChange}
                                        >
                                            <option value="Select an Account" >Select An Account</option>
                                            <optgroup label="Your Facebook Pages">
                                                {details.pages.map((page) => (
                                                    <option
                                                        key={page.id}
                                                        value={
                                                            page.name +
                                                            "___CR___" +
                                                            page.access_token +
                                                            "___CR___page___CR___" +
                                                            page.id
                                                        }
                                                    >
                                                        {page.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                            <optgroup label="Your Facebook Groups">
                                                {groups.map((group) => (
                                                    <option
                                                        key={group.id}
                                                        value={
                                                            group.name +
                                                            "___CR___" +
                                                            details.accessToken +
                                                            "___CR___group___CR___" +
                                                            group.id
                                                        }
                                                    >
                                                        {group.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3' style={{ display: "flex", justifyContent: "right" }}>
                            <button className="ms-2 demoLink" type='button' style={{ cursor: "pointer" }} onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}><span>{loader ? <>{details.buttonText} <FaSpinner className="spin" /> </> : details.buttonText} </span></button>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>


            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />
        </>
    )
}

export default ConnectFacebook;
