import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onSubmitKeyLead, onUpdateLead, onGetLead, onGenerateLead } from "../../Actions/ResourceAction";
import queryString from "query-string";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { onFetch, onGetClient } from "../../Actions/ClientsAction";
import Template from "../LandingPage/Template";
import { FiUpload } from "react-icons/fi";
import defaultImage from "../../images/default_image.jpg"
import ImagesModal from "../LandingPage/Modals/ImagesModal";
import { IoMdArrowRoundBack } from "react-icons/io"
import Script from "../TranslateScript/Script";


const CreateLeadMagnet = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const campId = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)

    const [templateList, setTemplateList] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true) }

    const [state, setState] = useState({
        keyword: "",
        savedTitle: "",
        savedContent: "",
        image: "",
        templateId: "51"
    })
    const [loader, setLoader] = useState({
        submitLoader: false,
        updateLoader: false,
        saveLoader: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submitLoader: true
        })
        let data = {
            id: campId,
            keyword: state.keyword,
            templateId: state.templateId
        }
        dispatch(onSubmitKeyLead(data, setState, loader, setLoader))
    }

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        } else {
            navigate(`/agency/create-client?id=${state.agencyId}&cId=${state.clientId}`)
        }
    }

    const handleUpdate = () => {
        setLoader({
            ...loader,
            updateLoader: true
        })
        dispatch(onUpdateLead(state, loader, setLoader))
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        let data = { ...state, id: campId }
        dispatch(onUpdateLead(data, loader, setLoader, true, navigate, aId, cId))
    }

    const handleAddImage = (data) => {
        setState({
            ...state,
            image: data.url
        })
    }

    const getTemplate = () => {
        let ep = "list-image-templates"
        let data = {
            type: "lead-magnet"
        }
        dispatch(onFetch(ep, data, setTemplateList))
    }

    const fetchLead = () => {
        let data = {
            id: campId
        }
        dispatch(onGetLead(data, setState))
    }
    useEffect(() => {
        if (campId) {
            fetchLead()
        }
    }, [campId])

    useEffect(() => {
        getTemplate()
    }, [])


    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])


    return (
        <>
            <div className="container">
                <div className="business-wrap">
                    <div className="business-top">
                        <h2>Create Lead Magnet</h2>
                        <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                    </div>

                    <div className="business-list">
                        <div className="proImage withDesc">
                            <div className="proImage-holder">
                                <img src={clientData.image} alt="" />
                            </div>
                            <div className="proImage-txt">
                                <p><strong>{clientData.name}</strong></p>
                                <p>{clientData.email}</p>
                                <p>{clientData.company}</p>
                            </div>
                        </div>
                    </div>

                    <div className="list-template text-center">
                        <div className="row">
                            {templateList.length > 0 ?
                                templateList.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Template
                                                curElem={curElem}
                                                setState={setState}
                                                state={state}
                                                thumbnail={curElem.previewUrl}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : ""
                            }
                        </div>
                    </div>

                    <div className="business-list">
                        <form className="row" onSubmit={handleSubmit}>
                            <div className="col-lg-6">
                                <div className="inpLabelWrap mt-0">
                                    <span className="labelTxt">Keyword</span>
                                    <div className="inpLabel">
                                        <label htmlFor="">Keyword</label>
                                        <input
                                            className="inpLabel-inp"
                                            placeholder="Enter keyword"
                                            type="text"
                                            name="keyword"
                                            value={state.keyword}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="btn-sec text-right col-lg-6 ">
                                <button className="demoLink" type="submit">
                                    {loader.submitLoader ? <>Creating Lead Magnet <i className="fa fa-spinner fa-spin mx-1" /></> : "Creating Lead Magnet"}
                                </button>
                            </div>
                            <div className="alert alert-info mt-4">
                                <strong>NOTE:</strong> Please note that generating AI content would take around 1-2 minutes. So please wait and let the process complete.
                            </div>
                        </form>



                        {state.savedTitle !== "" ?
                            <div className="inpLabelWrap">

                                <span className="labelTxt">Title</span>
                                <div className="inpLabel">
                                    <label htmlFor="">Generated Title</label>
                                    <input
                                        className="inpLabel-inp"
                                        name="savedTitle"
                                        id=""
                                        onChange={handleChange}
                                        value={state.savedTitle}
                                        placeholder="Generated Title"
                                    />
                                </div>

                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Image</span>
                                    <div className="inpLabel">
                                        <span
                                            onClick={() => handleShow()}
                                            className="fullLink cursor-pointer"></span>
                                        <span className='uploaded-img'><img src={state.image !== "" ? state.image : defaultImage} alt="" /></span>
                                        <input
                                            className="inpLabel-inp"
                                            placeholder=""
                                            type="text"
                                        />
                                        <span className="inpBtn-icon">
                                            <FiUpload /> <span className="pl-1">Choose</span>
                                        </span>
                                    </div>
                                </div>

                                <span className="labelTxt mt-2">Article</span>
                                <div className="inpLabel">
                                    <label htmlFor="" style={{ width: "100%", paddingBottom: "10px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #ffbebe", borderLeft: "1px solid #ffbebe", borderRight: "1px solid #ffbebe" }}>Article</label>
                                    <textarea
                                        className="inpLabel-inp blog_textarea"
                                        name="savedContent"
                                        id=""
                                        onChange={handleChange}
                                        value={state.savedContent}
                                        placeholder="Add Article"
                                    />
                                </div>
                                <Script scriptData={state} setScriptData={setState} type="leadmagnet" />
                                <div className="btn-sec text-right">
                                    <button className="demoLink"
                                        onClick={handleSave}
                                    >
                                        {loader.saveLoader ? <>Generating
                                            <i className="fa fa-spinner fa-spin mx-1" /></> : `Save & Generate`}
                                    </button>

                                    <button className="demoLink"
                                        onClick={handleUpdate}
                                    >
                                        {loader.updateLoader ? <>Saving
                                            <i className="fa fa-spinner fa-spin mx-1" /></> : `Save`}
                                    </button>
                                </div>
                            </div> : ""}

                    </div>

                </div>
            </div>
            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddImage}
                aId={aId}
                cId={cId}
            />

        </>
    )
}

export default CreateLeadMagnet;