import React, { useState } from 'react';
import addReelIcon from "../../../../images/add-reel.svg";
import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from 'react-redux';
import Alert from '../../../Alert'
import { BiMicrophoneOff } from 'react-icons/bi'
import { BiMicrophone } from 'react-icons/bi'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import loginGg from '../../../../images/login-bg.jpg'
import { ReactComponent as TransitionIcon } from '../../../../images/transition.svg'
import { updateSelectedTiles, handleDragStatus, updateVideoThumbnail, removeTtsFromSlides, addNewSlide, deleteTile, onAddSlide, onReorderData } from '../../../../Actions/VideoAction';
import { setAlert } from '../../../../Actions/AlertAction';
import SweetAlertTwo from '../../../SweetAlertTwo';
import Swal from 'sweetalert2';



const VideoTiles = ({ slideData }) => {

  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState({
    id: -1,
    enable: false,
    activeStatus: ""
  })


  const handleSweetAlert = (indexNumber, indexActiveStatus, e) => {
    e.stopPropagation()
    let data = [...slideData]
    if (data.length === 1) {
      Swal.fire({
        icon: "error",
        title: 'Oops...',
        text: "You cannot delete this slide!",
        confirmButtonColor: "#d03e3e"
      })
    } else {
      setInfo({
        ...info,
        id: indexNumber,
        enable: true,
        activeStatus: indexActiveStatus
      })
    }
  }

  const handleCancle = () => {
    setInfo({
      ...info,
      id: -1,
      enable: false,
      activeStatus: ""
    })
  }

  const onConfirm = (Swal1) => {
    let newIndex = info.id
    if (info.activeStatus === "1") {
      if (info.id === 0) {
        newIndex = info.id + 1;
      } else {
        newIndex = info.id - 1;
      }
      dispatch(deleteTile(info.id, newIndex))
      Swal1.close()
    }
    else {
      dispatch(deleteTile(info.id, false))
      Swal1.close()
    }
    setInfo({
      ...info,
      id: -1,
      enable: false,
      activeStatus: ""
    })

  }

  const onSetRecorder = (result) => {
    if (!result.destination) {
      return;
    }
    dispatch(onReorderData(result.source.index, result.destination.index));
  }

  const onSelectTile = (index) => {
    dispatch(updateSelectedTiles(index))
  }


  const handleTts = (slideIndex, e) => {
    e.stopPropagation()
    dispatch(setAlert("Voiceover removed successfully", "success"))
    dispatch(removeTtsFromSlides(slideIndex))
  }

  const handleAddSlide = (vId) => {
    if (slideData.length < 31) {
      let data = {
        videoId: vId
      }
      setLoader(true)
      dispatch(onAddSlide(data, setLoader))
    } else {
      dispatch(setAlert("You can have maximum 30 slides in a chapter.", "danger"))
    }
  }

  return (
    <>
      <div className="editorReelwrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="editorReel-scroll">
                <DragDropContext onDragEnd={onSetRecorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {
                          slideData.length > 0 ?
                            slideData.map((curElem, index) => {
                              return (
                                <>
                                  <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                    {(provided) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        onClick={() => onSelectTile(index)}
                                        className={curElem.isSelected === "1" ? 'selected-tile' : 'selected-tile-hover'}
                                      >
                                        <div
                                          className="editorReel-single "
                                          style={curElem.isSelected === "1" ? { border: 'none', } : {}}
                                        >
                                          {curElem.background.type !== "color" ?
                                            <img
                                              src={curElem.background.poster ? curElem.background.poster : loginGg}
                                              alt='tiles-poster'
                                              style={{ width: 'auto', height: '100%', cursor: 'pointer' }}
                                            />
                                            :
                                            <div
                                              // src={curElem.background.poster ? curElem.background.poster : loginGg}
                                              alt='tiles-poster'
                                              style={{ width: '100%', height: '100%', cursor: 'pointer', background: `${curElem.background.poster}` }}
                                            />
                                          }

                                          <FiTrash2
                                            className="delIcon"
                                            color='#fff'
                                            style={
                                              {
                                                cursor: 'pointer',
                                                color: `${curElem.isSelected === "1" ? '#a804a8' : ''}`,
                                              }
                                            }
                                            onClick={(e) => handleSweetAlert(index, curElem.isSelected, e)}
                                          />
                                          {curElem.voiceOver.enable ?
                                            <OverlayTrigger
                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow' >
                                                  TTS Active
                                                </Tooltip>
                                              }

                                            >
                                              <div>
                                                <BiMicrophone
                                                  className="voiceIcn"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#d64343' : ''}` }}
                                                  onClick={(e) => handleTts(index, e)}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger
                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow'>
                                                  TTS Inactive
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <BiMicrophoneOff
                                                  className="voiceIcn-off"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#a804a8' : ''}` }}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          }
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                  {curElem.transition.enable ?
                                    <div className="transition-wrapper" title={curElem.transition.simpleTransition}>
                                      <svg width="22px" height="22px" viewBox="0 0 22 22" >
                                        <TransitionIcon style={{ color: '#fff' }} />
                                      </svg>
                                    </div> : ""}

                                  <div className="addReelBt" onClick={() => handleAddSlide(curElem.videoId)}>
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#d03e3e", fontSize: '25px' }} /> :
                                      <img src={addReelIcon} alt="" />}
                                  </div>
                                </>
                              )
                            }) : ''
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <SweetAlertTwo
                  show={info.enable}
                  title="Are You Sure?"
                  text="Do you want to delete this!"
                  icon="warning"
                  showCancelButton={true}
                  confirmButtonColor='#3085d6'
                  cancelButtonColor='#d33'
                  confirmButtonText='Yes, delete it!'
                  confirmTitle='Deleting...'
                  confirmText="This won't be revert!"
                  confirmIcon='success'
                  handlePerform={onConfirm}
                  onCancel={handleCancle}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};




export default VideoTiles;
