import React from 'react'

const Template = ({ curElem, state, setState, thumbnail }) => {
    const handlePreview = () => {
        window.open(curElem.previewUrl, "_blank")
    }
    const handleUse = () => {
        setState({
            ...state,
            templateId: curElem.id
        })
    }
    return (
        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 " >
            <div className="list-template-wrap"  >
                <div
                    style={{ border: state.templateId === curElem.id ? "2px solid #ff6363" : "" }}
                    className={` ${curElem.type === "businessCard" || curElem.type === "social-media-covers" ? "list_template_business" : curElem.type === "letterhead" ? "list_template_letter" : curElem.type === "invoice" ? "list_template_invoice" : curElem.type === "envelope" ? "list_template_envelope" : "list_template_landing"}`}>
                    <img src={thumbnail} alt="" style={{ height: curElem.type === "invoice" ? "158px" : "auto" }} />
                    <div className="list-template-btn">
                        <div>
                            <span className="demoLink" onClick={handlePreview}>Preview</span>
                            <span className="demoLink" onClick={handleUse}>Use</span>
                        </div>
                    </div>
                </div>
                <h6 className='text-capitalize'>{curElem.name}</h6>
            </div>
        </div>
    )
}

export default Template