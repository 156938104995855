import React from 'react';
import { Tab, Nav } from "react-bootstrap";
import Alert from '../../../Alert';
import UploadComponent from '../../uploadFiles/UploadComponent';
import AiVoice from './AiVoice';
import Recording from './Recording';

const VoiceOver = ({ slideData }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    return (
        <div className="tabInner">
            <Alert />
            <div className="media-block">
                <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-tab">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-audio">Upload Voiceover</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="recording-tab">Recording</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="voice-tab">
                            <AiVoice
                                slideData={slideData}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-audio">
                            <UploadComponent
                                type="voiceover"
                                tabType="voiceover"
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="recording-tab">
                            <Recording
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Pane>
                    </Tab.Content>

                </Tab.Container>
            </div>
        </div>
    )
}

export default VoiceOver