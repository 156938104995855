import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onGoogleLangFetch, onTranslateData } from '../../Actions/ClientsAction';
import { useSelector } from 'react-redux';


const Scripts = ({ scriptData, setScriptData, type }) => {
    const dispatch = useDispatch()
    const memberShip = useSelector(state => state.auth.user.membership)
    const [lang, setLang] = useState([])
    const [state, setState] = useState({
        loader: false,
        language: ''
    })

    const translateBtn = () => {
        setState({
            ...state,
            loader: true
        })
        dispatch(onTranslateData(state, setState, scriptData, setScriptData, type))
    }


    useEffect(() => {
        if (memberShip.length > 0) {
            dispatch(onGoogleLangFetch(setLang, memberShip))
        }
    }, [memberShip])

    return (
        <div className="tabInner">
            <div className="story-block">
                {memberShip.includes("platinum") || memberShip.includes("platinum-lite") || memberShip.includes("fastpass") || memberShip.includes("bundle") ?
                    <div className='add-block-single'>
                        <div className="translate-drop mb-3 mt-2">
                            <select
                                name="tCode"
                                onChange={(e) => setState({
                                    ...state,
                                    language: e.target.value
                                })}
                            >
                                <option value="">Select Language</option>
                                {lang.length > 0 ?
                                    lang.map((curElem) => {
                                        return (
                                            <option
                                                value={curElem.code}
                                                key={curElem.id}
                                            >
                                                {curElem.name}
                                            </option>
                                        )
                                    })
                                    : ""}
                            </select>

                            <button className="demoLink trans_btn"
                                onClick={translateBtn}
                                disabled={state.language === "" || state.loader ? true : false}
                                style={{ background: state.language === "" ? "gray" : "" }}
                            >

                                {state.loader ?
                                    <>Translating <i className="fa fa-spinner fa-spin mx-1" /></>
                                    : "Translate"}
                            </button>
                        </div>

                    </div>
                    : ""}
            </div>
        </div>
    );
};

export default Scripts;
