import produce from "immer"

const initialState = {
    data: {
        id: "",
        agencyId: "",
        name: "",
        email: "",
        image: "",
        mode: "",
        earning: "",
        frequency: "",
        company: "",
        isEmail: false
    },
    landing: {
        id: "",
        agencyId: "",
        clientId: "",
        industryId: "",
        templateId: "",
        liveLink: "",
        domain: "",
        isPublish: "",
        headers: {
            enable: false,
            heading: "",
            bannerImage: "",
            logoImage: "",
            buttonText: "",
            buttonAction: "",
            customeUrl: "",
            bannerDescription: ""
        },
        videos: {
            enable: false,
            heading: "",
            source: "",
            url: ""
        },
        benefits: {
            enable: false,
            heading: "",
            benefits: [{
                heading: "",
                icon: "",
                description: ""
            }]
        },
        about: {
            enable: false,
            heading: "",
            image: "",
            paragraphs: [{
                text: ""
            }]
        },
        team: {
            enable: false,
            heading: "",
            members: [{
                image: "",
                name: "",
                role: "",
                description: ""
            }]
        },
        contact: {
            enable: false,
            logo: "",
            heading: "",
            daysOpen: {
                fromDay: "Monday",
                fromTime: 9,
                fromMeridiem: "AM",
                toDay: "Friday",
                toTime: 9,
                toMeridiem: "PM"
            }
            ,
            workingHours: 12,
            address: "",
            emailTitle: "",
            email: "",
            phoneTitle: "",
            phone: "",
            autoResponder: "",
            arrListId: ""
        },
        footer: {
            enable: false,
            tosText: "",
            privacyText: "",
            tosUrl: "",
            privacyUrl: "",
            copyrightText: ""
        },

    }
}

export const ClientReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_CLIENT":
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.name]: action.payload.value,
                }
            }


        case "SET_CLIENT_DATA":
            return produce(state, (draft) => {
                draft.data.id = action.payload.id
                draft.data.agencyId = action.payload.agencyId
                draft.data.name = action.payload.name
                draft.data.email = action.payload.email
                draft.data.image = action.payload.image
                draft.data.earning = action.payload.clientsEarning[0].earning
                draft.data.frequency = action.payload.clientsEarning[0].frequency
                draft.data.company = action.payload.clientsEarning[0].company
                draft.data.mode = action.payload.mode
                draft.data.industry = action.payload.industry
            })

        case "SET_CLIENT_LOGO":
            return produce(state, (draft) => {
                draft.data.image = action.payload
            })

        case "ON_GET_LANDING":
            return produce(state, (draft) => {
                draft.landing = action.payload
            })

        case "ON_CHECKED_TOGGLE":
            return produce(state, (draft) => {
                if (action.payload.name === "header") {
                    draft.landing.headers.enable = action.payload.checked
                } else if (action.payload.name === "video") {
                    draft.landing.videos.enable = action.payload.checked
                } else if (action.payload.name === "benefit") {
                    draft.landing.benefits.enable = action.payload.checked
                } else if (action.payload.name === "about") {
                    draft.landing.about.enable = action.payload.checked
                } else if (action.payload.name === "team") {
                    draft.landing.team.enable = action.payload.checked
                } else if (action.payload.name === "contact") {
                    draft.landing.contact.enable = action.payload.checked
                } else if (action.payload.name === "footer") {
                    draft.landing.footer.enable = action.payload.checked
                }
            })

        case "ON_CHANGE_HEADER":
            return {
                ...state,
                landing: {
                    ...state.landing,
                    headers: {
                        ...state.landing.headers,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "ON_CHANGE_VIDEO":
            return {
                ...state,
                landing: {
                    ...state.landing,
                    videos: {
                        ...state.landing.videos,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "ON_CHANGE_BENEFIT":
            return produce(state, (draft) => {
                if (action.payload.index !== undefined) {
                    draft.landing.benefits.benefits[action.payload.index] = {
                        ...draft.landing.benefits.benefits[action.payload.index],
                        [action.payload.name]: action.payload.value
                    }
                } else {
                    draft.landing.benefits.heading = action.payload.value
                }
            })

        case "ON_ADD_BENEFIT":
            return produce(state, (draft) => {
                draft.landing.benefits.benefits.push({
                    heading: "",
                    icon: "",
                    description: ""
                })
            })

        case "ON_CANCLE_BENEFIT":
            return produce(state, (draft) => {
                draft.landing.benefits.benefits.splice(action.payload, 1)
            })
        case "ON_CHANGE_ABOUT":
            return produce(state, (draft) => {
                if (action.payload.index !== undefined) {
                    draft.landing.about.paragraphs[action.payload.index] = {
                        ...draft.landing.about.paragraphs[action.payload.index],
                        text: action.payload.value
                    }
                } else {
                    draft.landing.about = {
                        ...draft.landing.about,
                        [action.payload.name]: action.payload.value
                    }
                }
            })

        case "ON_CHANGE_ABOUT_IMAGE":
            return produce(state, (draft) => {
                draft.landing.about.image = action.payload
            })

        case "ON_ADD_PARA":
            return produce(state, (draft) => {
                draft.landing.about.paragraphs.push({
                    text: ""
                })
            })

        case "ON_CANCEL_PARA":
            return produce(state, (draft) => {
                draft.landing.about.paragraphs.splice(action.payload, 1)
            })

        case "ON_CHANGE_TEAM":
            return produce(state, (draft) => {
                if (action.payload.index !== undefined) {
                    draft.landing.team.members[action.payload.index] = {
                        ...draft.landing.team.members[action.payload.index],
                        [action.payload.name]: action.payload.value
                    }
                } else {
                    draft.landing.team.heading = action.payload.value
                }
            })

        case "ON_ADD_MEMBER":
            return produce(state, (draft) => {
                draft.landing.team.members.push({
                    image: "",
                    name: "",
                    role: "",
                    description: ""
                })
            })

        case "ON_CANCEL_MEMBER":
            return produce(state, (draft) => {
                draft.landing.team.members.splice(action.payload, 1)
            })

        case "ON_CHANGE_CONTACT":
            return {
                ...state,
                landing: {
                    ...state.landing,
                    contact: {
                        ...state.landing.contact,
                        [action.payload.name]: action.payload.value
                    }
                }
            }

        case "ON_CHECKED_CONTACT":
            if (action.payload.name === "workingHours") {
                return produce(state, (draft) => {
                    draft.landing.contact.workingHours = action.payload.value
                    if (+action.payload.value === 24) {

                        if (draft.landing.contact.daysOpen.fromMeridiem === "PM") {
                            draft.landing.contact.daysOpen.fromTime = + draft.landing.contact.daysOpen.fromTime + 12
                        }
                        if (draft.landing.contact.daysOpen.toMeridiem === "PM") {
                            draft.landing.contact.daysOpen.toTime = + draft.landing.contact.daysOpen.toTime + 12
                        }

                    } else {

                        if (draft.landing.contact.daysOpen.fromTime >= 12) {
                            draft.landing.contact.daysOpen.fromTime = + draft.landing.contact.daysOpen.fromTime - 12
                        }
                        if (draft.landing.contact.daysOpen.toTime >= 12) {
                            draft.landing.contact.daysOpen.toTime = + draft.landing.contact.daysOpen.toTime - 12
                        }

                    }
                })
            } else {
                return {
                    ...state,
                    landing: {
                        ...state.landing,
                        contact: {
                            ...state.landing.contact,
                            [action.payload.name]: action.payload.value
                        }
                    }
                }
            }

        case "ON_CHANGE_DAYS":
            return produce(state, (draft) => {
                draft.landing.contact.daysOpen = {
                    ...draft.landing.contact.daysOpen,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_FOOTER":
            return {
                ...state,
                landing: {
                    ...state.landing,
                    footer: {
                        ...state.landing.footer,
                        [action.payload.name]: action.payload.value
                    }
                }
            }


        default:
            return state
    }
}