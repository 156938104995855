import React, { useEffect, useState } from 'react'
import { Rnd } from "react-rnd";
const TextLayer = (props) => {
    const [myStyle, setMyStyle] = useState({
        color: props.layer.style.fontColor,
        fontFamily: props.layer.style.fontFamily,
        backgroundColor: props.layer.style.backgroundColor,
        fontSize: parseInt(props.layer.style.fontSize),
        textAlign: props.layer.style.textAlign,
        fontWeight: props.layer.style.fontWeight,
        fontStyle: props.layer.style.fontStyle,
        textTransform: props.layer.style.textTransform,
        textDecoration: props.layer.style.textDecoration,
        lineHeight: props.layer.style.lineHeight,
        opacity: parseInt(props.layer.style.opacity),
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        position: "absolute",
        overflow: "hidden"
    })

    useEffect(() => {
        const obj = {
            color: props.layer.style.fontColor,
            fontFamily: props.layer.style.fontFamily,
            backgroundColor: props.layer.style.backgroundColor,
            fontSize: parseInt(props.layer.style.fontSize),
            textAlign: props.layer.style.textAlign,
            fontWeight: props.layer.style.fontWeight,
            fontStyle: props.layer.style.fontStyle,
            textTransform: props.layer.style.textTransform,
            textDecoration: props.layer.style.textDecoration,
            lineHeight: props.layer.style.lineHeight,
            opacity: parseInt(props.layer.style.opacity),
            left: props.layer.position.left,
            top: props.layer.position.top,
            width: props.layer.size.width,
            height: props.layer.size.height,
            position: "absolute",
            overflow: "hidden"

        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size])

    const localStyle = {
        color: myStyle.color,
        fontFamily: myStyle.fontFamily,
        backgroundColor: myStyle.backgroundColor,
        fontSize: parseInt(myStyle.fontSize),
        textAlign: myStyle.textAlign,
        fontWeight: myStyle.fontWeight,
        fontStyle: myStyle.fontStyle,
        textTransform: myStyle.textTransform,
        textDecoration: myStyle.textDecoration,
        lineHeight: myStyle.lineHeight,
        opacity: parseInt(myStyle.opacity),
        position: "absolute",
        paddingLeft: '8px',
        overflow: "hidden"
    }



    return (
        <Rnd
            dragGrid={[1, 1]}
            style={localStyle}
            className={props.layer.isSelected ? "layer-border" : ""}
            enableResizing={props.layer.isSelected ? true : false}
            disableDragging={props.layer.isSelected ? false : true}
            onClick={() => props.handleSelectLayer(props.layerIndex)}
            default={{ x: 0, y: 0, width: 100, height: 100 }}
            size={{ width: myStyle.width, height: myStyle.height }}
            position={{ x: myStyle.left, y: myStyle.top }}
            onDragStop={(e, d) => {
                setMyStyle({ ...myStyle, left: d.x, top: d.y })
                props.handleRePosition(d.y, d.x, props.layerIndex)
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height, top: position.x, left: position.y });
                props.handleResize(ref.style.width, ref.style.height, position.x, position.y, props.layerIndex)
            }}
            bounds=".canvasEditor-main"
        >
            {props.layer.text}
        </Rnd>
    )
}

export default TextLayer