import React, { useState, useEffect } from 'react'
import { Rnd } from "react-rnd";

const ImageLayer = (props) => {
    const [loader, setLoader] = useState(false)
    const [myStyle, setMyStyle] = useState({
        opacity: parseInt(props.layer.style.opacity),
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        position: "absolute",
    })

    useEffect(() => {
        const obj = {
            opacity: parseInt(props.layer.style.opacity),
            left: props.layer.position.left,
            top: props.layer.position.top,
            width: props.layer.size.width,
            height: props.layer.size.height,
            position: "absolute",
        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size])

    useEffect(() => {
        setLoader(true)
    }, [props.layer.src]);

    return (
        <>
            <Rnd
                // lockAspectRatio={true}
                style={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
                enableResizing={props.layer.isSelected ? true : false}
                disableDragging={props.layer.isSelected ? false : true}
                enableUserSelectHack={true}
                onClick={() => props.handleSelectLayer(props.layerIndex)}
                default={{ x: 0, y: 0, height: myStyle.height }}
                size={{ width: myStyle.width, height: myStyle.height }}
                position={{ x: myStyle.left, y: myStyle.top }}
                onDragStop={(e, d) => {
                    setMyStyle({ ...myStyle, left: d.x, top: d.y })
                    props.handleRePosition(d.y, d.x, props.layerIndex)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height });
                    props.handleResize(ref.style.width, ref.style.height, position.x, position.y, props.layerIndex)
                }}
                bounds=".canvasEditor-main"
                className={props.layer.isSelected ? "layer-border" : ""}
            >
                {
                    loader ?
                        <div className='media-loader' style={{ position: "absolute" }}>
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                        : ""
                }

                <img
                    style={{ display: "block", height: '100%', pointerEvents: "none" }}
                    src={props.layer.src}
                    alt=''
                    onLoad={()=> setLoader(false)}
                />
            </Rnd>
        </>
    )
}

export default ImageLayer