import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { onChangeContact, onCheckedContact, onFetchAutoResponderList, onFetchAutoresponderListData } from '../../Actions/ClientsAction';
import ImagesModal from './Modals/ImagesModal';
import defaultImage from "../../images/default_image.jpg"
import DaysOpen from './DaysOpen';

const ContactSection = ({ aId, cId }) => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const [responderList, setResponderList] = useState([])
    const [responderListData, setResponderListData] = useState([])

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
   

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "autoResponder") {
            const selectedResponder = responderList.find((elem) => elem.id === value);
            if (selectedResponder) {
                let ep;
                if (selectedResponder.social === "mailchimp") {
                    ep = "mc-list"
                } else if (selectedResponder.social == "aweber") {
                    ep = "aw-list"
                } else if (selectedResponder.social == "getResponse") {
                    ep = "gr-list"
                } else if (selectedResponder.social == "mailvio") {
                    ep = "mv-list"
                }
                let data = { id: value }
                dispatch(onFetchAutoresponderListData(data, ep, setResponderListData))
            } else {
                setResponderListData([])
            }
            dispatch(onChangeContact(name, value))
        }
        dispatch(onChangeContact(name, value))
    }

    const handleChecked = (e) => {
        const { name, checked, value } = e.target
        dispatch(onCheckedContact(name, checked, value))
    }

    const handleAddContactLogo = (data) => {
        dispatch(onChangeContact("logo", data.url))
    }

    const fetchAutoresponderList = () => {
        let data = { agencyId: aId, clientId: cId }
        dispatch(onFetchAutoResponderList(data, setResponderList))
    }

    useEffect(() => {
        if (aId) {
            fetchAutoresponderList()
        }
    }, [aId])


    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Contact Logo</span>
                <div className="inpLabel">
                    <span onClick={handleShow} className="fullLink cursor-pointer"></span>
                    <span className='uploaded-img'><img src={landingData.contact.logo !== "" ? landingData.contact.logo : defaultImage} alt="" /></span>
                    <input
                        className="inpLabel-inp"
                        placeholder=""
                        type="text"
                    />
                    <span className="inpBtn-icon">
                        <FiUpload /> <span className="pl-1">Choose</span>
                    </span>
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Contact Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Contact Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Contact Heading"
                        type="text"
                        name='heading'
                        value={landingData.contact.heading}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>


            <div className="inpLabelWrap">
                <span className="labelTxt">Working Hours</span>
                <div className="d-flex align-items-center">
                    <label className="switch">
                        <input
                            type="checkbox"
                            name="workingHours"
                            checked={+landingData.contact.workingHours === 24 ? true : false}
                            value={+landingData.contact.workingHours === 24 ? 12 : 24}
                            onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                    </label>
                    <span className='slideTxt'>{landingData.contact.workingHours} Hours</span>
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Days Open</span>
                <DaysOpen />
            </div>


            <div className="row">
                <div className="col-lg-12">
                    <div className="inpLabelWrap mt-5">
                        <span className="labelTxt">Address</span>
                        <div className="inpLabel">
                            <label htmlFor="address">Address</label>
                            <textarea
                                className="inpLabel-inp"
                                name="address"
                                placeholder="Enter Address"
                                value={landingData.contact.address}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Email Title</span>
                <div className="inpLabel">
                    <label htmlFor="emailTitle">Email Title</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Email Title"
                        type="text"
                        name='emailTitle'
                        value={landingData.contact.emailTitle}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Email</span>
                <div className="inpLabel">
                    <label htmlFor="email">Email</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Email"
                        type="email"
                        name='email'
                        value={landingData.contact.email}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Mobile Number Title</span>
                <div className="inpLabel">
                    <label htmlFor="phoneTitle">Mobile Number Title</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Mobile Number Title"
                        type="text"
                        name='phoneTitle'
                        value={landingData.contact.phoneTitle}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Mobile Number</span>
                <div className="inpLabel">
                    <label htmlFor="phone">Mobile Number</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Mobile Number"
                        type="text"
                        name='phone'
                        value={landingData.contact.phone}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Auto-Responder</span>
                <div className="inpLabel">
                    <label htmlFor="">Select Auto-Responder</label>
                    <select
                        className="inpLabel-inp"
                        name='autoResponder'
                        value={landingData.contact.autoResponder}
                        onChange={(e) => handleChange(e)}
                    >
                        <option value="">Select Auto-Responder</option>
                        {responderList.length > 0 ?
                            responderList.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.id}>{`${curElem.data.user_name}-${curElem.social}`}</option>
                                )
                            })
                            : ""}
                    </select>
                </div>
            </div>

            <div className="inpLabelWrap">
                <span className="labelTxt">Auto-Responder list</span>
                <div className="inpLabel">
                    <label htmlFor="">Select Auto-Responder list</label>
                    <select
                        className="inpLabel-inp"
                        name='autoResponderList'
                        value={landingData.contact.autoResponderList}
                        onChange={(e) => handleChange(e)}
                    >
                        <option value="">Select Auto-Responder list</option>
                        {responderListData.length > 0 ?
                            responderListData.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.id}>{`${curElem.name}`}</option>
                                )
                            })
                            : ""}
                    </select>
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddContactLogo}
            />

        </div>
    )
}

export default ContactSection